import { useParams } from "react-router-dom";
import React, { useState } from "react";
import altelis_logo from "../../assets/images/altelis-full.svg";
import not_found from "../../assets/images/order-404.svg";
import valid from "../../assets/images/valid-subscription.png";
import { CircularProgress, Grid, TextField } from "@mui/material";
import axios from "axios";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import toastOptions from "../../assets/constants/toast";
import { HandTap } from "@phosphor-icons/react";

const TestSubscribe = () => {
    const { testId } = useParams();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasSubscribed, setHasSubscribed] = useState(false);
    const [subscription, setSubscription] = useState({
        startDate: dayjs(dayjs().add(2, "hour").format("YYYY-MM-DD HH:00")),
    });
    const [error, setError] = useState(false);
    const [test, setTest] = useState();

    React.useEffect(() => {
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "tests/" + testId + "/public")
            .then((response) => {
                if (response.data && response.data.name) {
                    console.log(response.data);
                    setTest(response.data);
                } else {
                    setError(true);
                }
                setIsLoaded(true);
            })
            .catch(() => {
                setError(true);
                setIsLoaded(true);
            });
    }, [testId]);

    const handleSubscribe = () => {
        setButtonLoading(true);
        axios
            .post(process.env.REACT_APP_UPA_API_HOST + "tests/subscribe", {
                ...subscription,
                testId: test.id,
            })
            .then((response) => {
                console.log(response.data);
                setButtonLoading(false);
                setHasSubscribed(true);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return isLoaded ? (
        !error ? (
            <div className="order-portal">
                <div className="portal-header">
                    <div className="portal-title">
                        <div className="logo-container">
                            <img src={altelis_logo} alt="portal-logo" width={100} />
                        </div>
                        <div>
                            <h1>{test.name}</h1>
                            <span>#{test.id}</span>
                        </div>
                    </div>
                    <a href="tel:01 85 09 74 06" className="portal-phone">
                        <span>01 85 09 74 06</span>
                    </a>
                </div>
                {dayjs() > dayjs(test.startDate) && dayjs() < dayjs(test.stopDate) ? (
                    !hasSubscribed ? (
                        <div className="test-content">
                            <h1>Inscrivez vous pour le test</h1>
                            <span className="test-duration">
                                <span>Durée du test : </span>
                                {test.hours > 0 ? (
                                    <strong>
                                        {test.hours} heure
                                        {test.hours > 1 ? "s" : ""}{" "}
                                    </strong>
                                ) : null}
                                {test.minutes > 0 ? (
                                    <strong>
                                        {test.minutes} minute
                                        {test.minutes > 1 ? "s" : ""}
                                    </strong>
                                ) : null}
                            </span>
                            <span>Veuillez choisir une date pour effectuer le test :</span>
                            <div className="test-form">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <DateTimePicker
                                            label="Créneau"
                                            ampm={false}
                                            minDateTime={dayjs() > dayjs(test.startDate) ? dayjs() : dayjs(test.startDate)}
                                            maxDateTime={dayjs(test.stopDate + " 23:59:59")}
                                            disableMaskedInput
                                            value={subscription.startDate}
                                            format="DD MMMM YYYY à HH:mm"
                                            onChange={(newValue) =>
                                                setSubscription({
                                                    ...subscription,
                                                    startDate: newValue,
                                                })
                                            }
                                            textField={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {subscription.startDate ? (
                                        <Grid item xs={12}>
                                            <div className="test-duration-details">
                                                <span>
                                                    Votre test sera programmé pour le{" "}
                                                    <strong>
                                                        {subscription.startDate.format("DD MMMM YYYY") +
                                                            " de " +
                                                            subscription.startDate.format("HH:mm") +
                                                            " à " +
                                                            subscription.startDate
                                                                .add(test.hours, "hours")
                                                                .add(test.minutes, "minutes")
                                                                .format("HH:mm")}
                                                    </strong>
                                                </span>
                                            </div>
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic"
                                            required
                                            label="Prénom"
                                            value={subscription.firstName ? subscription.firstName : ""}
                                            onChange={(e) =>
                                                setSubscription({
                                                    ...subscription,
                                                    firstName: capitalize(e.target.value),
                                                })
                                            }
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic"
                                            required
                                            label="Nom"
                                            value={subscription.lastName ? subscription.lastName : ""}
                                            onChange={(e) =>
                                                setSubscription({
                                                    ...subscription,
                                                    lastName: e.target.value.toUpperCase(),
                                                })
                                            }
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="email"
                                            required
                                            id="outlined-basic"
                                            label="Adresse email"
                                            value={subscription.email ? subscription.email : ""}
                                            onChange={(e) =>
                                                setSubscription({
                                                    ...subscription,
                                                    email: e.target.value,
                                                })
                                            }
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <div className="test-submit">
                                    {buttonLoading ? (
                                        <button className="btn btn-primary login-button-loading" disabled>
                                            <CircularProgress size={20} className="white-loader" />
                                        </button>
                                    ) : (
                                        <button className="main-button blue-button" onClick={handleSubscribe}>
                                            M'inscrire
                                            <HandTap size={16} weight="bold" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="portal-content flex-view">
                            <div className="subscribed">
                                <img src={valid} alt="not found" width={120} />
                                <h1>Merci !</h1>
                                <br />
                                <p>
                                    Votre demande de test a bien été enregistrée. Vous recevrez une convocation par email contenant toutes les
                                    informations relatives au test.
                                </p>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="portal-content flex-view">
                        <div className="order-404">
                            <img src={not_found} alt="not found" width={400} />
                            <h1>Oups...</h1>
                            <p>
                                Ce test n'est disponible que du <strong>{dayjs(test.startDate).format("DD MMMM YYYY")}</strong> au{" "}
                                <strong>{dayjs(test.stopDate).format("DD MMMM YYYY")}</strong>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className="portal-content flex-view">
                <div className="order-404">
                    <img src={not_found} alt="not found" width={400} />
                    <h1>Oups...</h1>
                    <p>Le test que vous cherchez n'est pas disponible pour le moment. Merci de réessayer plus tard.</p>
                </div>
            </div>
        )
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default TestSubscribe;
