import { Link } from "react-router-dom";
import CustomList from "../../custom/CustomList";
import React, { useContext, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import CustomTag from "../../custom/CustomTag";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import { Plus } from "@phosphor-icons/react";

const RoleList = () => {
    const { token } = useContext(AuthContext);
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refreshCounter, setRefreshCounter] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: "Nom",
                accessor: "name",
                icon: "user",
            },
            {
                Header: "Utilisateurs",
                accessor: "users",
                icon: "user",
                Cell: ({ cell: { value } }) => <CustomTag value={value ? value.length : 0} />,
            },
        ],
        []
    );

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs rôles")) {
            axios
                .post(
                    process.env.REACT_APP_UPA_API_HOST + "users/roles/bulk-delete",
                    { selectedIds },
                    { headers: { Authorization: "Bearer " + token } }
                )
                .then(() => {
                    toast.success("Rôles supprimés", toastOptions);
                    setRefreshCounter(refreshCounter + 1);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    };
    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title roles">
                    <h1>Gestion des rôles</h1>
                </div>
                <div className="view-buttons">
                    {selectedColumns === 0 ? (
                        <div>
                            <Link to="new">
                                <button className="main-button blue-button plus">
                                    <Plus size={16} weight="bold" />
                                    Ajouter un rôle
                                </button>
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <button className="main-button warning-button delete" onClick={handleBulkDelete}>
                                Supprimer
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="tabs-container">
                <CustomList
                    entity="users/roles"
                    refreshData={refreshCounter}
                    columns={columns}
                    setSelectedColumns={setSelectedColumns}
                    setSelectedIds={setSelectedIds}
                />
            </div>
        </div>
    );
};

export default RoleList;
