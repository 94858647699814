import {Check, DotsThree, Lightning, Plus, X} from "@phosphor-icons/react";
import React, {useContext, useEffect, useState} from "react";
import {Switch} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CustomSearchInput from "../../custom/CustomSearchInput";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import AuthContext from "../../../contexts/AuthContext";

const AutomationsButton = ({ models, automations, setAutomations }) => {

    const { token } = useContext(AuthContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [windowOpen, setWindowOpen] = useState(false);
    const [editedAutomation, setEditedAutomation] = useState({ repeatEvery: [0, 0, 0, 0, 0] });
    const [warningModal, setWarningModal] = React.useState(false);

    useEffect(() => console.log(editedAutomation), [editedAutomation]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditedAutomation({ repeatEvery: [0, 0, 0, 0, 0] });
    }

    const handleSubmitAutomation = () => {
        if (editedAutomation.modelId && editedAutomation.repeatEvery && editedAutomation.repeatEvery.includes(1)) {
            if(editedAutomation.id) {
                axios.put(process.env.REACT_APP_UPA_API_HOST + 'tracking/automations/' + editedAutomation.id, editedAutomation, { headers: { Authorization: `Bearer ${token}` }})
                    .then((response) => {
                        setModalOpen(false);
                        setAutomations(automations.map(a => a.id === editedAutomation.id ? response.data : a));
                        setEditedAutomation({ repeatEvery: [0, 0, 0, 0, 0] });
                        toast.success("Modèle modifié avec succès", toastOptions);
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            } else {
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'tracking/automations', editedAutomation, { headers: { Authorization: `Bearer ${token}` }})
                    .then((response) => {
                        setModalOpen(false);
                        setAutomations([...automations, response.data]);
                        setEditedAutomation({ repeatEvery: [0, 0, 0, 0, 0] });
                        toast.success("Modèle ajouté avec succès", toastOptions);
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            }
        } else {
            toast.error("Merci de remplir tous les champs (et au moins 1 jour de récurrence)", toastOptions);
        }
    }

    const toggleDay = (index) => {
        setEditedAutomation((prev) => {
            const newRepeatEvery = [...prev.repeatEvery];
            newRepeatEvery[index] = newRepeatEvery[index] === 0 ? 1 : 0;
            return { ...prev, repeatEvery: newRepeatEvery };
        });
    };

    const handleChangeAutomationStatus = (automation) => {
        setAutomations(automations.map(a => a.id === automation.id ? { ...automation, isActive: !automation.isActive } : a));
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'tracking/automations/' + automation.id, { ...automation, isActive: !automation.isActive }, { headers: { Authorization: `Bearer ${token}` }})
            .then((response) => {
                setAutomations(automations.map(a => a.id === automation.id ? response.data : a));
            })
            .catch(error => {
                toast.error("Une erreur est survenue", toastOptions);
                console.error("Error saving entry", error);
            });
    }

    const handleChangeAutomationRecurrence = (automation, day) => {
        const updatedRepeatEvery = automation.repeatEvery.map((r, index) => index === day ? r === 0 ? 1 : 0 : r);
        if (!updatedRepeatEvery.includes(1)) {
            toast.error("Une automatisation doit avoir au moins un jour sélectionné", toastOptions);
            return;
        }
        setAutomations(automations.map(a => a.id === automation.id ? { ...automation, repeatEvery: updatedRepeatEvery } : a));
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'tracking/automations/' + automation.id, { ...automation, repeatEvery: updatedRepeatEvery }, { headers: { Authorization: `Bearer ${token}` }})
            .then((response) => {
                setAutomations(automations.map(a => a.id === automation.id ? response.data : a));
            })
            .catch(error => {
                toast.error("Une erreur est survenue", toastOptions);
                console.error("Error saving entry", error);
            });
    }

    const handleEditAutomation = (automation) => {
        setEditedAutomation(automation);
        setModalOpen(true);
    }

    const handleDeleteAutomation = (automationId) => {
        axios.delete(process.env.REACT_APP_UPA_API_HOST + 'tracking/automations/' + automationId, { headers: { Authorization: `Bearer ${token}` }})
            .then(() => {
                setAutomations(automations.filter(a => a.id !== automationId));
                setWarningModal(false);
                toast.success("Automatisation supprimée avec succès", toastOptions);
            })
            .catch(error => {
                toast.error("Une erreur est survenue", toastOptions);
                console.error("Error saving entry", error);
            });
    }

    return (
        <>
            <button className='squared-button automation' onClick={() => setWindowOpen(true)}><Lightning size={16} color="#3b61e1" weight="fill" /></button>
            <div className={`automations-window-container ${!windowOpen && 'hidden'}`}>
                <div className='automations-header'>
                    <div className='flex-center gap-2'>
                        <Lightning size={18} color="#3b61e1" weight="bold" />
                        <h1>Automatisations</h1>
                    </div>
                    <button className='close-button' onClick={() => setWindowOpen(false)}><X size={14} color="#7a7a7a" weight="bold" /></button>
                </div>
                { automations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((automation, index) => (
                    <div key={index} className={`automation-line ${!automation.isActive && 'inactive' }`}>
                        <div className='automation-line-header'>
                            <h2>{models.find(m => m.id === automation.modelId).name}</h2>
                            <div className='flex-center gap-2'>
                                <Tooltip placement="left" id='models-tooltip' title={
                                    <React.Fragment>
                                        <div className='tooltip-models-options-container' onClick={(e) => e.stopPropagation()}>
                                            <span className='edit' onClick={() => handleEditAutomation(automation)}>Modifier</span>
                                            <span className='delete' onClick={() => setWarningModal(automation)}>Supprimer</span>
                                        </div>
                                    </React.Fragment>
                                }>
                                    <div className='models-options-button' onClick={(e) => e.stopPropagation()}>
                                        <DotsThree size={16} color="#7d7d7d" weight="bold" />
                                    </div>
                                </Tooltip>
                                <Switch checked={automation.isActive} onChange={() => handleChangeAutomationStatus(automation)} color="primary" />
                            </div>
                        </div>
                        <div className='repeat-every-select'>
                            <div className='flex-start gap-1'>
                                {["Lun", "Mar", "Mer", "Jeu", "Ven"].map((day, index) => (
                                    <span key={index} onClick={() => handleChangeAutomationRecurrence(automation, index)} className={automation.repeatEvery[index] === 1 ? "selected" : ""}>{day}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
                <button className='main-button blue-button' onClick={() => setModalOpen(true)}><Plus size={16} weight="bold"/>Nouvelle automatisation</button>
            </div>
            <CustomModal show={modalOpen} setShow={handleCloseModal} title={editedAutomation?.id ? editedAutomation.name : 'Nouvelle automatisation'}>
                <div className="modal-new-model">
                    <div className='margin-bottom'>
                        <label className='model'>Modèle de tâche à automatiser</label>
                        <CustomSearchInput
                            className="modal-model-select"
                            isClearable
                            entity={models}
                            labelType="model"
                            value={editedAutomation?.modelId ? models.find(t => t.id === editedAutomation.modelId) : null}
                            onChange={(value) => setEditedAutomation({ ...editedAutomation, modelId: value ? value.id : null })}
                            placeholder="Sélectionnez un modèle"
                        />
                    </div>
                    <div className='repeat-every-select'>
                        <div className='flex-start gap-2'>
                            <label className="repeat">Répéter tous les : </label>
                            {["Lun", "Mar", "Mer", "Jeu", "Ven"].map((day, index) => (
                                <span
                                    key={index}
                                    className={editedAutomation.repeatEvery[index] === 1 ? "selected" : ""}
                                    onClick={() => toggleDay(index)}
                                >
                                    {day}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className='modal-buttons'>
                        <button className='main-button cancel-button' onClick={handleCloseModal}>Annuler</button>
                        <button className="main-button green-button validate" onClick={handleSubmitAutomation}><Check size={16} color="#f0f0f0" weight="bold" />Enregistrer</button>
                    </div>
                </div>
            </CustomModal>
            <CustomModal show={warningModal !== false} setShow={setWarningModal} title="Attention">
                <div className="modal-new-model">
                    <p>Êtes-vous sûr de vouloir supprimer l'automatisation : {warningModal ? warningModal.model.name : ''} ?</p>
                    <div className='modal-buttons'>
                        <button className='main-button cancel-button' onClick={() => setWarningModal(false)}>Annuler</button>
                        <button className="main-button red-button validate" onClick={() => handleDeleteAutomation(warningModal.id)}>Supprimer l'automatisation</button>
                    </div>
                </div>
            </CustomModal>
        </>
    )
}

export default AutomationsButton;