import React from "react";
import Papa from "papaparse";

const CsvImporter = ({ data, setData, label = "Déposez votre fichier" }) => {
    const changeHandler = (event) => {
        if (event.target.files[0]) {
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setData(results.data);
                },
            });
            event.target.value = null;
        }
    };

    return (
        <div>
            <label htmlFor="file" className={data ? "metas-file-chooser data" : "metas-file-chooser"}>
                <span>{!data ? label : " Fichier importé"}</span>
                <input accept=".csv" type="file" name="file" id="file" onChange={changeHandler} />
            </label>
        </div>
    );
};

export default CsvImporter;
