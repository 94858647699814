import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";
import CustomSearchInput from "../../custom/CustomSearchInput";
import TaskOption from "./TaskOption";
import {IconButton, TextField} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useContext} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import AuthContext from "../../../contexts/AuthContext";
import check from "../../../assets/icons/check-circle--green.svg";
import alert from "../../../assets/icons/alert--orange.svg";
import {Lightning, Plus, Question} from "@phosphor-icons/react";
import Tooltip from "@mui/material/Tooltip";
import ModelsButton from "./ModelsButton";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import frLocale from 'date-fns/locale/fr';

const TimeTable = ({ entries, setEntries, projects, tasks, sections, handleAddEntry, tab, automations, setAutomations, models, setModels }) => {

    const { token } = useContext(AuthContext);

    const handleActivateEditing = (id) => {
        setEntries(entries.map(entry => entry.id === id ? {...entry, isEditing: true, originalEntry: {...entry}} : (entry.originalEntry ? {...entry.originalEntry, isEditing: false} : {...entry, isEditing: false}) ));
    };

    const handleEditEntry = (id, updatedEntry) => {
        setEntries(entries.map(entry => ((entry.tempId ? entry.tempId : entry.id) === id ? updatedEntry : entry)));
    };

    const handleCancelEdit = (id) => {
        setEntries(entries.map(entry => ((entry.tempId ? entry.tempId : entry.id) === id ? {...entry.originalEntry, isEditing: false} : entry)));
    };

    const handleDeleteEntry = (entry) => {
        if(entry.tempId) {
            setEntries(entries.filter(e => e.tempId !== entry.tempId));
        } else {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + `tracking/entries/${entry.id}`, { headers: { Authorization: `Bearer ${token}` }})
                .then(() => setEntries(entries.filter(e => e.id !== entry.id)))
                .catch(error => {
                    toast.error("Une erreur est survenue", toastOptions);
                    console.error("Error deleting entry", error);
                });
        }
    };

    const handleSubmitEntry = entry => {
        if (entry.projectId && entry.taskId && entry.duration > 0) {
            if(entry.tempId) {
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'tracking/entries', entry, { headers: { Authorization: `Bearer ${token}` }})
                    .then(response => {
                        setEntries(entries.map(e => e.tempId === entry.tempId ? {...response.data, projectId: response.data.task.section.projectId } : e));
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            } else {
                axios.put(process.env.REACT_APP_UPA_API_HOST + `tracking/entries/${entry.id}`, entry, { headers: { Authorization: `Bearer ${token}` }})
                    .then(response => {
                        setEntries(entries.map(e => e.id === entry.id ? {...response.data, projectId: response.data.task.section.projectId } : e));
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            }
        } else {
            toast.error("Merci de remplir tous les champs", toastOptions);
        }
    }

    const getEntriesFiltered = () => {
        switch (tab) {
            case 0:
                return entries.filter(entry => dayjs(entry.date).isSame(dayjs(), 'day'));
            case 1:
                return entries.filter(entry => dayjs(entry.date).isSame(dayjs().subtract(1, 'day'), 'day'));
            case 2:
                return entries.filter(entry => dayjs(entry.date).isSame(dayjs().add(1, 'day'), 'day'));
            default:
                return entries;
        }
    };

    const taskFilter = (option, inputValue) => {
        if (!inputValue) return true;

        const searchValue = inputValue.toLowerCase();
        const task = option.data;
        const section = sections.find(s => s.id === task.sectionId);
        const parent = task.parentId ? tasks.find(t => t.id === task.parentId) : null;

        if (task.name.toLowerCase().includes(searchValue)) return true;
        if (section && section.name.toLowerCase().includes(searchValue)) return true;
        return !!(parent && parent.name.toLowerCase().includes(searchValue));

    };

    const getEntriesDurationSum = getEntriesFiltered().reduce((sum, entry) => sum + (entry.tempId ? 0 : entry.duration), 0);

    return (
        <div>
            { tab !== 3 && (
                <div className='tracking-hours-remaining-container'>
                    <span>Heures restantes à remplir : </span>
                    <span className='tracking-hours-remaining-value'>{Math.max(0, 7 - getEntriesDurationSum)} h</span>
                    { getEntriesDurationSum >= 7 ? <img alt='check' src={check} width={18}/> : <img alt='alert' src={alert} width={18}/>}
                </div>
            )}
            <div className="custom-tracking-table-container">
                <table className="custom-tracking-table">
                    <thead>
                    <tr>
                        <th className='date'>Date</th>
                        <th className='project'>Projet</th>
                        <th className='task'>Tâche</th>
                        <th className='comments'>Commentaires</th>
                        <th className='duration'>Durée</th>
                        <th className='actions'>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    { getEntriesFiltered().map((entry) => (
                        <tr key={entry.id || entry.tempId}>
                            { (tab !== 3 || (tab === 3 && !entry.isEditing)) && (
                                <td className='entry-line-date' onClick={() => { if(tab === 3) handleActivateEditing(entry.id) }} ><CustomTag value={dayjs(entry.date).format('DD/MM/YYYY')}/></td>
                            )}
                            {entry.tempId || entry.isEditing ? (
                                <>
                                    { tab === 3 && (
                                        <td className='entry-line-date editable'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
                                                <DatePicker
                                                    value={dayjs(entry.date)}
                                                    onChange={(newValue) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {...entry, date: newValue.format('YYYY-MM-DD')})}
                                                    renderInput={(props) => <TextField {...props} />}
                                                    format="DD/MM/YYYY"
                                                    disableFuture={false}
                                                    disablePast={false}
                                                    className="w-full"
                                                    slotProps={{
                                                        textField: {
                                                            placeholder: '',
                                                            sx: {
                                                                '& .MuiInputBase-root': {
                                                                    height: '40px',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    height: '40px',
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    transform: 'translate(14px, 8px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(14px, -9px) scale(0.75)',
                                                                    },
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    cursor: 'pointer',
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </td>
                                    )}
                                    <td className="entry-line-project">
                                        <CustomSearchInput
                                            isClearable
                                            entity={projects}
                                            labelType="project"
                                            value={projects.find(p => p.id === entry.projectId)}
                                            onChange={(value) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                ...entry,
                                                projectId: value ? value.id : null,
                                                taskId: null
                                            })}
                                            placeholder="Rechercher"
                                        />
                                    </td>
                                    <td className="entry-line-task">
                                        <CustomSearchInput
                                            entity={entry.projectId ? tasks.filter(t => sections.some(s => s.projectId === entry.projectId && s.id === t.sectionId)) : []}
                                            value={entry.taskId ? tasks.find(t => t.id === entry.taskId) : null}
                                            onChange={(value) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                ...entry,
                                                taskId: value ? value.id : null
                                            })}
                                            placeholder="Rechercher"
                                            isClearable
                                            Option={(props) => <TaskOption {...props} tasks={tasks} sections={sections}/>}
                                            filterOption={taskFilter}
                                        />
                                    </td>
                                    <td className="entry-line-commments">
                                        <input
                                            type="text"
                                            className="classic-input comment-input"
                                            value={entry.comments}
                                            onChange={(event) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                ...entry,
                                                comments: event.target.value
                                            })}
                                        />
                                    </td>
                                    <td className="entry-line-duration">
                                        <input
                                            type="number"
                                            min={0}
                                            step={.25}
                                            className="classic-input number-input"
                                            value={entry.duration}
                                            onChange={(event) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                ...entry,
                                                duration: parseFloat(event.target.value)
                                            })}
                                        />
                                    </td>
                                    <td className="entry-line-actions">
                                        <IconButton aria-label="done" size="small" onClick={() => handleSubmitEntry(entry)}>
                                            <DoneIcon fontSize="inherit"/>
                                        </IconButton>
                                        <IconButton aria-label="done" size="small" onClick={() => {
                                            if (entry.tempId) {
                                                handleDeleteEntry(entry);
                                            } else {
                                                handleCancelEdit(entry.id);
                                            }
                                        }}>
                                            <CloseIcon fontSize="inherit"/>
                                        </IconButton>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td className="entry-line-project" onClick={() => handleActivateEditing(entry.id)}>
                                        <span>{projects.find(p => p.id === entry.task.section.projectId).name}</span>
                                    </td>
                                    <td className="entry-line-task" onClick={() => handleActivateEditing(entry.id)}>
                                        <CustomTag value={entry.task.name}/>
                                    </td>
                                    <td className="entry-line-commments" onClick={() => handleActivateEditing(entry.id)}>
                                        <span className='comments'>{entry.comments}</span>
                                    </td>
                                    <td className="entry-line-duration" onClick={() => handleActivateEditing(entry.id)}>
                                        <CustomTag value={entry.duration + ' h'}/>
                                    </td>
                                    <td className="entry-line-actions">
                                        <IconButton aria-label="delete" size="small" onClick={() => handleActivateEditing(entry.id)}>
                                            <EditIcon fontSize="inherit"/>
                                        </IconButton>
                                        <IconButton aria-label="delete" size="small" onClick={() => handleDeleteEntry(entry)}>
                                            <DeleteIcon fontSize="inherit"/>
                                        </IconButton>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                    { tab === 2 && automations.filter(a => a.isActive && a.repeatEvery[new Date().getDay()] === 1).map((a, index) => (
                        <tr key={index} className="automation-generated-line">
                            <td className='entry-line-date'><CustomTag value={dayjs().add(1, 'day').format('DD/MM/YYYY')}/></td>
                            <td className="entry-line-project">
                                <span>{projects.find(p => p.id === tasks.find(t => t.id === a.model.taskId).section.projectId).name}</span>
                            </td>
                            <td className="entry-line-task">
                                <CustomTag value={tasks.find(t => t.id === a.model.taskId).name}/>
                            </td>
                            <td className="entry-line-commments">
                                <span className='comments'>{a.model.comments}</span>
                            </td>
                            <td className="entry-line-duration">
                                <CustomTag value={a.model.duration + ' h'}/>
                            </td>
                            <td className="entry-line-actions">
                                <IconButton aria-label="delete" size="small">
                                    <Lightning size={18} color="#3b61e1" weight="bold" />
                                </IconButton>
                                <Tooltip placement="left" id='models-tooltip' title='Cette tâche sera créée automatiquement demain.'>
                                    <IconButton aria-label="delete" size="small">
                                        <Question size={18} color="#7a7a7a" weight="bold" />
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4}>
                            <div className='new-entry'>
                                <button className='main-button new-line-button add' onClick={handleAddEntry}><Plus size={16} color="#3b61e1" weight="bold" />Nouvelle saisie</button>
                                <ModelsButton
                                    models={models}
                                    setModels={setModels}
                                    projects={projects}
                                    sections={sections}
                                    tasks={tasks}
                                    entries={entries}
                                    setEntries={setEntries}
                                    automations={automations}
                                    setAutomations={setAutomations}
                                    tab={tab}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className='entries-totals'>
                        <td colSpan={4} className='entries-count'>{getEntriesFiltered().length} tâche(s)</td>
                        <td colSpan={2}>{getEntriesFiltered().reduce((sum, entry) => sum + (entry.tempId ? 0 : entry.duration), 0)} h</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TimeTable;