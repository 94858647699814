import md5 from "md5";
import React, { useContext, useState } from "react";
import axios from "axios";
import "../../assets/styles/Login.css";
import AuthContext from "../../contexts/AuthContext";
import full_logo from "../../assets/images/main-logo.png";
import { Link, Navigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const { state } = useLocation();
    const { user, setToken } = useContext(AuthContext);

    if (redirectToReferrer === true || user) {
        return <Navigate to={state?.from || "/admin"} />;
    }

    const handleSubmit = (event) => {
        if (event.type === "click" || event.key === "Enter") {
            setLoader(true);
            axios
                .post(process.env.REACT_APP_UPA_API_HOST + "login", {
                    email: email,
                    password: md5(password),
                })
                .then((response) => {
                    setLoader(false);
                    localStorage.setItem("session_token", response.data.token);
                    setToken(response.data.token);
                    setRedirectToReferrer(true);
                })
                .catch((error) => {
                    setError(error.response.data.message);
                    setLoader(false);
                });
        }
    };

    return (
        <div className="main-login">
            <div className="login-background" />
            <div className="login-container">
                <div className="login-form">
                    <div className="img-container">
                        <img src={full_logo} alt="logo" width={180} />
                    </div>
                    <div>
                        <h1>Bienvenue</h1>
                        <p className="welcome-message">Connectez-vous à Altelis Tools</p>
                        <div className="custom-hr" />
                        {error ? <p className="login-error">{error}</p> : null}
                        <div className="login-input-group">
                            <span>
                                Email <span className="required"> *</span>
                            </span>
                            <input
                                type="email"
                                placeholder="Saisissez votre adresse email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                }}
                                onKeyUp={(e) => handleSubmit(e)}
                            />
                        </div>
                        <div className="login-input-group">
                            <span>
                                Mot de passe <span className="required"> *</span>
                            </span>
                            <input
                                type="password"
                                placeholder="Saisissez votre mot de passe"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setError("");
                                }}
                                onKeyUp={(e) => handleSubmit(e)}
                            />
                        </div>
                        <Link to="/forgot-password" className="forgot-password">
                            Mot de passe oublié ?
                        </Link>
                        <div className="login-button-container">
                            {loader ? (
                                <button className="btn btn-primary login-button-loading" disabled>
                                    <CircularProgress size={20} className="white-loader" />
                                </button>
                            ) : (
                                <button className="btn btn-primary login-button" type="submit" onClick={handleSubmit}>
                                    Se connecter
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
