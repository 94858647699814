import React, { useState } from "react";
import { CSVLink } from "react-csv";
import constants from "../../assets/constants/constants";
import { DownloadSimple } from "@phosphor-icons/react";

const CustomDownloader = ({ data, columns, filename, smallButton = false }) => {
    const [formattedColumns, setFormattedColumns] = useState(null);
    const [formattedData, setFormattedData] = useState(null);

    React.useEffect(() => {
        if (data && columns) {
            let filteredColumns = columns.filter(
                (column) => column.isDownloaded === undefined || (column.isDownloaded && column.isDownloaded === true)
            );
            setFormattedColumns(
                filteredColumns.map((column) => {
                    return { ...column, key: column.downloadAccessor ? column.downloadAccessor : column.accessor, label: column.Header };
                })
            );
            const newData = JSON.parse(JSON.stringify(data));
            setFormattedData(
                newData.map((row) => {
                    for (let dataKey in row) {
                        if (dataKey === "credit" || dataKey === "debit") {
                            row[dataKey] = row[dataKey] && row[dataKey].toFixed(2);
                        } else {
                            row[dataKey] = constants.all.find((constant) => constant.value === row[dataKey])
                                ? constants.all.find((constant) => constant.value === row[dataKey]).label
                                : row[dataKey];
                        }
                    }
                    return row;
                })
            );
        }
    }, [data, columns]);

    return formattedData && formattedColumns ? (
        <CSVLink data={formattedData} filename={filename ? filename : "extract.csv"} headers={formattedColumns}>
            {smallButton ? (
                <button className="main-button download-button">
                    <DownloadSimple size={16} weight="bold" />
                </button>
            ) : (
                <button className="main-button download">
                    <DownloadSimple size={16} weight="bold" />
                    Télécharger
                </button>
            )}
        </CSVLink>
    ) : null;
};

export default CustomDownloader;
