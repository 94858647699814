import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import CustomList from "../../../custom/CustomList";
import CustomTag from "../../../custom/CustomTag";
import { Link } from "react-router-dom";
import CsvImporter from "./CsvImporter";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { FileArrowDown } from "@phosphor-icons/react";
import CustomSelect from "../../../custom/CustomSelect";
import BillCreator from "./BillCreator";

const Billing = () => {
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [refreshData, setRefreshData] = useState(0);
    const { token } = useContext(AuthContext);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format("YYYY-MM"));
    const [monthOptions, setMonthOptions] = useState([]);

    useEffect(() => {
        generateLast12Months();
    }, []);

    const generateLast12Months = () => {
        const months = [];
        const currentDate = dayjs();
        for (let i = 0; i < 12; i++) {
            const date = currentDate.subtract(i, "month");
            months.push({ label: date.format("MMMM YYYY"), value: date.format("YYYY-MM") });
        }
        setMonthOptions(months);
        setSelectedMonth(months[0].value);
    };

    const fetchData = () => {
        setIsLoaded(false);
        axios
            .get(`${process.env.REACT_APP_UPA_API_HOST}metas/billing?month=${selectedMonth}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setData(response.data.sort((a, b) => a.contact.data.customer_name.localeCompare(b.contact.data.customer_name)));
                setIsLoaded(true);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                toast.error(error.message, toastOptions);
                setIsLoaded(true);
            });
    };

    useEffect(() => {
        fetchData();
    }, [selectedMonth, refreshData]);

    useEffect(() => {
        if (csvData) {
            setData((data) =>
                data.map((row) => {
                    let billingCost = csvData.find((csvRow) => csvRow["Code moteur"] === row.engineCode);
                    billingCost = billingCost ? parseFloat(billingCost["Montant à facturer TTC"]) : 0;
                    return { ...row, billingCost: billingCost };
                })
            );
        }
    }, [csvData]);

    const handleMonthChange = (selectedOption) => {
        setSelectedMonth(selectedOption.value);
    };

    const formatAmount = (amount, digits = 0) => {
        return Number(amount).toLocaleString("fr-FR", {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        });
    };

    const monthNames = {
        "01": "january",
        "02": "february",
        "03": "march",
        "04": "april",
        "05": "may",
        "06": "june",
        "07": "july",
        "08": "august",
        "09": "september",
        "10": "october",
        "11": "november",
        "12": "december",
    };

    const downloadTemplate = () => {
        axios
            .get(`${process.env.REACT_APP_UPA_API_HOST}metas/billing/template?month=${selectedMonth}`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
            })
            .then((response) => {
                const [year, month] = selectedMonth.split("-");
                const formattedMonth = `${monthNames[month]}_${year}`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `metas_billing_${formattedMonth}.csv`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error("Error downloading template:", error);
                toast.error("Erreur lors du téléchargement du template.", toastOptions);
            });
    };

    const columns = [
        {
            Header: "Nom de l'hôtel",
            id: "hotel",
            icon: "user",
            Cell: (row) => (
                <div className="billing-metas-amount">
                    <Link to={`/admin/inventaire/clients/${row.row.original.contact.id}`}>{row.row.original.contact.data.customer_name}</Link>
                    <CustomTag className={row.row.original.engineType} value={row.row.original.engineCode} />
                    <CustomTag value={row.row.original.taxId} />
                </div>
            ),
        },
        {
            Header: "Réservations",
            accessor: "bookingsAmount",
            icon: "euro",
            Cell: ({ cell: { value } }) => <span>{formatAmount(value) + " €"}</span>,
        },
        {
            Header: "Réservations métas",
            icon: "euro",
            Cell: (row) => (
                <div className="billing-metas-amount">
                    <span>{formatAmount(row.row.original.metasAmount) + " €"}</span>
                    <CustomTag value={parseInt(row.row.original.metaPercentage) + "%"} />
                </div>
            ),
        },
        {
            Header: "Estimation",
            icon: "percentage",
            Cell: (row) => (
                <CustomTag
                    className={row.row.original.estimatedMinAmount >= 1000 ? "debit" : "credit"}
                    value={formatAmount(row.row.original.estimatedMinAmount) + " - " + formatAmount(row.row.original.estimatedMaxAmount) + " €"}
                />
            ),
        },
        {
            Header: "Montant à facturer TTC",
            icon: "percentage",
            Cell: (row) =>
                row.row.original.billingCost ? (
                    <CustomTag className="credit" value={formatAmount(row.row.original.billingCost, 2) + " €"} />
                ) : (
                    "0,00 €"
                ),
        },
    ];

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title pennylane">
                    <h1>Facturation métas (Metas cost)</h1>
                    <CustomTag value={data.length} />
                </div>
                <div className="view-buttons">
                    <CustomSelect
                        options={monthOptions}
                        value={monthOptions.find((option) => option.value === selectedMonth)}
                        onChange={handleMonthChange}
                        width={200}
                    />
                    <div className="user-info-hr-vertical"></div>
                    <button className="main-button" onClick={downloadTemplate}>
                        <FileArrowDown size={16} weight="bold" />
                        Télécharger le template CSV
                    </button>
                    <BillCreator transactions={data} setTransactions={setData} refreshData={refreshData} setRefreshData={setRefreshData} />
                </div>
            </div>
            <p className="waiting-order-warning no-margin-bottom">
                <span>
                    Saisie des montants pour la facturation Métas pour le mois de <strong>{dayjs(selectedMonth).format("MMMM YYYY")}</strong>.
                </span>
            </p>
            <CsvImporter data={csvData} setData={setCsvData} label="Déposez le fichier CSV complété" />
            <div className="tabs-container">
                <CustomList
                    entity={data}
                    isSelectable={false}
                    isEditable={true}
                    isFetchable={false}
                    isClickable={false}
                    columns={columns}
                    setData={setData}
                />
            </div>
        </div>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default Billing;
