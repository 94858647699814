import React, { useState } from "react";
import { Pencil, Check } from "@phosphor-icons/react";
import { CircularProgress, Switch, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import CustomTag from "../../custom/CustomTag";
import CustomSearchInput from "../../custom/CustomSearchInput";
import CustomOption from "../../custom/CustomOption";

const colorPalette = {
    gris: "#C6C6C6",
    rouge: "#FFCFC7",
    orange: "#FFD0BB",
    jaune: "#F1CB9C",
    vert: "#C0E6C5",
    bleu: "#CDE6F9",
    violet: "#D6BAFC",
    rose: "#F8A9C5",
};

const GeneralView = ({ user, roles, onUpdateUser, onColorChange }) => {
    const [isEditingFirstName, setIsEditingFirstName] = useState(false);
    const [isEditingLastName, setIsEditingLastName] = useState(false);
    const [isEditingRole, setIsEditingRole] = useState(false);
    const [isEditingHoliday, setIsEditingHoliday] = useState(false);
    const [isEditingColor, setIsEditingColor] = useState(false);
    const [editedFirstName, setEditedFirstName] = useState(user.firstName);
    const [editedLastName, setEditedLastName] = useState(user.lastName);
    const [editedRoleId, setEditedRoleId] = useState(user.roleId);
    const [editedHolidayActive, setEditedHolidayActive] = useState(user.holidayActive);
    const [editedHolidayStartingBalance, setEditedHolidayStartingBalance] = useState(user.holidayStartingBalance);
    const [selectedColor, setSelectedColor] = useState(user.backgroundColor);
    const [isSaving, setIsSaving] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleColorSelect = (color) => {
        setSelectedColor(color);
        setIsEditingColor(true);
        onColorChange(color);
    };

    const handleSave = async () => {
        setIsSaving(true);
        await onUpdateUser({
            ...user,
            firstName: editedFirstName,
            lastName: editedLastName,
            roleId: editedRoleId,
            holidayActive: editedHolidayActive,
            holidayStartingBalance: editedHolidayStartingBalance,
            backgroundColor: selectedColor,
        });
        setIsSaving(false);
        resetEditing();
    };

    const resetEditing = () => {
        setIsEditingFirstName(false);
        setIsEditingLastName(false);
        setIsEditingRole(false);
        setIsEditingHoliday(false);
        setIsEditingColor(false);
    };

    return (
        <div className="tab-panel">
            <div className="tab-panel-header">
                <h2>Dashboard</h2>
                <span>Vue générale de votre profil, email, rôle...</span>
            </div>

            <hr />

            <div className="tab-panel-content">
                <div className="input-row-container">
                    <span className="input-label large email">Prénom</span>
                    {isEditingFirstName ? (
                        <input
                            value={editedFirstName}
                            className="classic-input large"
                            onChange={(e) => setEditedFirstName(e.target.value)}
                            placeholder="Entrez un prénom"
                        />
                    ) : (
                        <span>{user.firstName}</span>
                    )}
                    <Pencil size={20} onClick={() => setIsEditingFirstName(!isEditingFirstName)} style={{ cursor: "pointer", marginLeft: "10px" }} />
                </div>

                <div className="input-row-container">
                    <span className="input-label large email">Nom</span>
                    {isEditingLastName ? (
                        <input
                            value={editedLastName}
                            className="classic-input large"
                            onChange={(e) => setEditedLastName(e.target.value)}
                            placeholder="Entrez un nom"
                        />
                    ) : (
                        <span>{user.lastName}</span>
                    )}
                    <Pencil size={20} onClick={() => setIsEditingLastName(!isEditingLastName)} style={{ cursor: "pointer", marginLeft: "10px" }} />
                </div>

                <div className="input-row-container">
                    <span className="input-label role">Rôle</span>
                    {isEditingRole ? (
                        <CustomSearchInput
                            placeholder="Rechercher un rôle"
                            entity={roles}
                            value={roles.find((role) => role.id === editedRoleId)}
                            onChange={(newValue) => setEditedRoleId(newValue.value)}
                            Option={CustomOption}
                        />
                    ) : (
                        <CustomTag value={user.role.name} />
                    )}
                    <Pencil size={20} onClick={() => setIsEditingRole(!isEditingRole)} style={{ cursor: "pointer", marginLeft: "10px" }} />
                </div>
            </div>

            <hr />

            <div className="tab-panel-content">
                <div className="input-row-container conges">
                    <span className="input-label large">Gestion des congés</span>
                    {isEditingHoliday ? (
                        <>
                            <Switch checked={editedHolidayActive} onChange={(e) => setEditedHolidayActive(e.target.checked)} />
                            {editedHolidayActive && (
                                <input
                                    type="number"
                                    value={editedHolidayStartingBalance}
                                    min={0}
                                    step={0.01}
                                    className="classic-input"
                                    onChange={(e) => setEditedHolidayStartingBalance(e.target.value)}
                                    placeholder="Solde de départ"
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <CustomTag value={user.holidayActive ? "Oui" : "Non"} className={user.holidayActive ? "active" : "inactive"} />
                            {user.holidayActive && (
                                <CustomTag value={`Solde de départ : ${user.holidayStartingBalance} j.`} className="margin-left" />
                            )}
                        </>
                    )}
                    <Pencil size={20} onClick={() => setIsEditingHoliday(!isEditingHoliday)} style={{ cursor: "pointer", marginLeft: "10px" }} />
                </div>
            </div>

            <hr />

            <div className="tab-panel-content">
                <div className="input-row-container">
                    <span className="input-label large color">Couleur (HEX)</span>
                    {selectedColor && <input type="text" className="classic-input" value={selectedColor} readOnly />}
                    <Pencil size={20} onClick={() => setDialogOpen(true)} style={{ cursor: "pointer", marginLeft: "10px" }} />
                </div>

                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>Choisissez une couleur</DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {Object.entries(colorPalette).map(([name, color]) => (
                                <div
                                    key={name}
                                    onClick={() => {
                                        handleColorSelect(color);
                                        setDialogOpen(false);
                                    }}
                                    style={{
                                        backgroundColor: color,
                                        width: "50px",
                                        height: "50px",
                                        margin: "5px",
                                        cursor: "pointer",
                                        border: selectedColor === color ? "2px solid black" : "none",
                                    }}
                                />
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Annuler</Button>
                    </DialogActions>
                </Dialog>
            </div>

            {(isEditingFirstName || isEditingLastName || isEditingRole || isEditingHoliday || isEditingColor) && (
                <div className="save-section">
                    <button className="main-button blue-button" onClick={handleSave} disabled={isSaving}>
                        {isSaving ? (
                            <CircularProgress size={20} className="white-loader" />
                        ) : (
                            <>
                                <Check size={16} weight="bold" />
                                Enregistrer
                            </>
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};

export default GeneralView;
