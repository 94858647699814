import {
    CalendarDot,
    CirclesThree,
    ClockClockwise,
    ClockCounterClockwise,
    ListDashes,
    Plus
} from "@phosphor-icons/react";
import {CircularProgress, Tab, Tabs} from "@mui/material";
import React, {useContext, useEffect} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import dayjs from "dayjs";
import TimeTable from "./TimeTable";
import ModelsButton from "./ModelsButton";
import AutomationsButton from "./AutomationsButton";
import CustomTag from "../../custom/CustomTag";

const TimeEntryList = () => {

    const [tab, setTab] = React.useState(0);
    const [entries, setEntries] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [sections, setSections] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [automations, setAutomations] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { token, user } = useContext(AuthContext);
    const urlParams = new URLSearchParams(window.location.search);
    const asanaAuth = urlParams.get('asana_auth');

    useEffect(() => console.log(entries), [entries]);

    useEffect(() => {
        if (asanaAuth && asanaAuth === 'success') {
            toast.success('Connexion avec Asana réussie', toastOptions);
            urlParams.delete('asana_auth');
            window.history.replaceState({}, document.title, window.location.pathname);
        } else if (asanaAuth && asanaAuth === 'failure') {
            toast.error('Connexion avec Asana échouée', toastOptions);
            urlParams.delete('asana_auth');
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, [asanaAuth]);

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'tracking/data', { headers: { Authorization: `Bearer ${token}` }})
            .then((response) => {
                console.log(response.data);
                setEntries(response.data.entries.map(entry => ({...entry, projectId: entry.task.section.projectId })));
                setProjects(response.data.projects);
                setSections(response.data.sections);
                setTasks(response.data.tasks);
                setModels(response.data.models);
                setAutomations(response.data.automations);
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                console.error("Erreur lors du chargement des tâches", error);
                setLoading(false);
            });
    }, [token]);

    const handleAddEntry = () => {
        let date;
        switch (tab) {
            case 0:
                date = dayjs().format('YYYY-MM-DD');
                break;
            case 1:
                date = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
                break;
            case 2:
                date = dayjs().add(1, 'day').format('YYYY-MM-DD');
                break;
            default:
                date = dayjs().format('YYYY-MM-DD');
        }
        setEntries([...entries, { tempId: Date.now(), date, comments: "", duration: 0 }]);
    };

    return !loading ? (
        <div className="view-container tracking">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Saisie d'activité</h1>
                </div>
                <div className='view-buttons'>
                    { !user.asanaAccessToken ? (
                        <a href={`https://app.asana.com/-/oauth_authorize?client_id=${process.env.REACT_APP_ASANA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_UPA_API_HOST}tracking/asana/auth&response_type=code&state=${user.id}`}>
                            <button className='main-button asana-button'><CirclesThree size={20} weight="fill" color='#ff584a' />Se connecter à Asana</button>
                        </a>
                    ) : (
                        <>
                            <AutomationsButton
                                models={models}
                                automations={automations}
                                setAutomations={setAutomations}
                            />
                            <div className='custom-dropdown-button'>
                                <button className="main-button blue-button dropdown-left" onClick={handleAddEntry}>
                                    <Plus size={16} weight="bold"/>
                                    Nouvelle saisie
                                </button>
                                <ModelsButton
                                    models={models}
                                    setModels={setModels}
                                    projects={projects}
                                    sections={sections}
                                    tasks={tasks}
                                    entries={entries}
                                    setEntries={setEntries}
                                    automations={automations}
                                    setAutomations={setAutomations}
                                    tab={tab}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            { !user.asanaAccessToken && (
                <>
                    <CustomTag value="La connexion à Asana est nécessaire pour commencer la saisie des temps" />
                    <div className='asana-blur-auth' />
                </>
            )}
            <div className="tabs-container">
                <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                    <Tab value={0} label={"Auj"} icon={<CalendarDot size={16} weight="bold" />} iconPosition="start"/>
                    <Tab value={1} label={"Hier"} icon={<ClockCounterClockwise size={16} weight="bold" />} iconPosition="start"/>
                    <Tab value={2} label={"Demain"} icon={<ClockClockwise size={16} weight="bold" />} iconPosition="start"/>
                    <Tab value={3} label={"Tout"} icon={<ListDashes size={16} weight="bold" />} iconPosition="start"/>
                </Tabs>
                {[0, 1, 2, 3].map((index) => (
                    <div key={index} role="tabpanel" id={index} hidden={tab !== index}>
                        <TimeTable
                            entries={entries}
                            setEntries={setEntries}
                            projects={projects}
                            sections={sections}
                            tasks={tasks}
                            handleAddEntry={handleAddEntry}
                            automations={automations}
                            models={models}
                            setModels={setModels}
                            setAutomations={setAutomations}
                            tab={tab}
                        />
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30}/>
        </div>
    )
}

export default TimeEntryList;