import React from "react";
import CustomList from "../../../custom/CustomList";
import CustomTag from "../../../custom/CustomTag";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Plus } from "@phosphor-icons/react";

const TestList = () => {
    const navigate = useNavigate();

    const columns = React.useMemo(
        () => [
            {
                Header: "Nom du test",
                accessor: "name",
                icon: "pen",
            },
            {
                Header: "Début du test",
                accessor: "startDate",
                icon: "calendar",
                Cell: ({ cell: { value } }) => (
                    <CustomTag value={dayjs(value).format("DD MMMM YYYY")} />
                ),
            },
            {
                Header: "Fin du test",
                accessor: "stopDate",
                icon: "calendar",
                Cell: ({ cell: { value } }) => (
                    <CustomTag value={dayjs(value).format("DD MMMM YYYY")} />
                ),
            },
        ],
        []
    );

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title tests">
                    <h1>Tests</h1>
                </div>
                <div className="view-buttons">
                    <button
                        className="main-button blue-button plus"
                        onClick={() => navigate("new")}
                    >
                        <Plus size={16} weight="bold" />
                        Créer un test
                    </button>
                </div>
            </div>
            <div className="tabs-container">
                <CustomList entity={"tests"} columns={columns} />
            </div>
        </div>
    );
};

export default TestList;
