import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import CustomSearchInput from "../../../custom/CustomSearchInput";
import { CircularProgress, Switch } from "@mui/material";
const GroupEdit = () => {
    const { groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        console.log(group);
    }, [group]);

    React.useEffect(() => {
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "books/groups/" + groupId, { headers: { Authorization: "Bearer " + token } })
            .then((response) => {
                if (response.data) {
                    console.log(response.data);
                    response.data.contacts = response.data.contacts.map((contact) => {
                        return { ...contact, value: contact.id, label: contact.data.contact_name };
                    });
                    setGroup(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error("Groupe inconnu", toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, groupId, navigate]);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios
            .put(process.env.REACT_APP_UPA_API_HOST + "books/groups/" + groupId, group, { headers: { Authorization: "Bearer " + token } })
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le groupe a bien été modifié", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    };

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="view-title">
                    <h1>{group.name}</h1>
                </div>
                <div className="view-buttons">
                    <button onClick={() => navigate(-1)} className="main-button without-icon warning-button">
                        Annuler
                    </button>
                    <button className="main-button blue-button validate margin-left" onClick={handleUpdate} disabled={buttonLoading}>
                        {buttonLoading ? <CircularProgress size={20} className="white-loader" /> : "Sauvegarder"}
                    </button>
                </div>
            </div>
            <div className="input-row-container">
                <span className="input-label id">
                    Nom du groupe <span className="required">*</span>
                </span>
                <div className="input-container without-icon">
                    <input
                        value={group.name ? group.name : ""}
                        className="classic-input"
                        placeholder="Entrez un nom"
                        onChange={(e) => {
                            setGroup((group) => {
                                return { ...group, name: e.target.value };
                            });
                        }}
                    />
                </div>
            </div>
            <div className="input-row-container">
                <span className="input-label role">
                    Support mutualisé <span className="required">*</span>
                </span>
                <div className="input-container without-icon switch">
                    <Switch checked={group.shared} onChange={(e) => setGroup({ ...group, shared: e.target.checked })} />
                </div>
            </div>
            <div className="input-row-container">
                <span className="input-label user">
                    Hôtels <span className="required">*</span>
                </span>
                <div className="input-container">
                    <CustomSearchInput
                        placeholder="Rechercher un hôtel"
                        entity="books/customers"
                        value={group.contacts}
                        labelType="zohoContact"
                        isMultiSearch
                        onChange={(newValue) => {
                            setGroup((group) => {
                                return { ...group, contacts: newValue };
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default GroupEdit;
