import React, {useContext, useEffect} from "react";
import {CaretDown, Check, DotsSixVertical, DotsThree, Lightning, Plus} from "@phosphor-icons/react";
import Tooltip from "@mui/material/Tooltip";
import CustomModal from "../../custom/CustomModal";
import CustomSearchInput from "../../custom/CustomSearchInput";
import TaskOption from "./TaskOption";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import AuthContext from "../../../contexts/AuthContext";
import dayjs from "dayjs";

const ModelsButton = ({ models, setModels, automations, setAutomations, projects, sections, tasks, entries, setEntries, tab }) => {

    const { token } = useContext(AuthContext);
    const [editedModel, setEditedModel] = React.useState({});
    const [modalOpen, setModalOpen] = React.useState(false);
    const [warningModal, setWarningModal] = React.useState(false);

    useEffect(() => console.log(editedModel), [editedModel]);

    const getDayFromTab = () => {
        switch (tab) {
            case 0:
                return dayjs().format('YYYY-MM-DD');
            case 1:
                return dayjs().subtract(1, 'day').format('YYYY-MM-DD');
            case 2:
                return dayjs().add(1, 'day').format('YYYY-MM-DD');
            default:
                return dayjs().format('YYYY-MM-DD');
        }
    }

    const handleSubmitModel = () => {
        if (editedModel && editedModel.projectId && editedModel.taskId && editedModel.duration > 0 && editedModel.name) {
            if(editedModel.id) {
                axios.put(process.env.REACT_APP_UPA_API_HOST + 'tracking/models/' + editedModel.id, editedModel, { headers: { Authorization: `Bearer ${token}` }})
                    .then((response) => {
                        setModalOpen(false);
                        setAutomations(automations.map(a => a.modelId === editedModel.id ? { ...a, model: response.data } : a));
                        setModels(models.map(m => m.id === editedModel.id ? response.data : m));
                        setEditedModel(null);
                        toast.success("Modèle modifié avec succès", toastOptions);
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            } else {
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'tracking/models', editedModel, { headers: { Authorization: `Bearer ${token}` }})
                    .then((response) => {
                        setModalOpen(false);
                        setModels([...models, response.data]);
                        setEditedModel(null);
                        toast.success("Modèle ajouté avec succès", toastOptions);
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            }
        } else {
            toast.error("Merci de remplir tous les champs", toastOptions);
        }
    }

    const handleDeleteModel = (id) => {
        axios.delete(process.env.REACT_APP_UPA_API_HOST + 'tracking/models/' + id, { headers: { Authorization: `Bearer ${token}` }})
            .then(() => {
                setAutomations(automations.filter(a => a.modelId !== id));
                setModels(models.filter(m => m.id !== id));
                setWarningModal(false);
                toast.success("Modèle supprimé avec succès", toastOptions);
            })
            .catch(error => {
                toast.error("Une erreur est survenue", toastOptions);
                console.error("Error deleting model", error);
            });
    }

    const handleDuplicateModel = (model) => {
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'tracking/models', { ...model, name: model.name + ' (copie)' }, { headers: { Authorization: `Bearer ${token}` }})
            .then((response) => {
                setModels([...models, response.data]);
                toast.success("Modèle dupliqué avec succès", toastOptions);
            })
            .catch(error => {
                toast.error("Une erreur est survenue", toastOptions);
                console.error("Error duplicating model", error);
            });
    }

    const handleAddTaskFromModel = (model) => {
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'tracking/entries', {...model, date: getDayFromTab() }, { headers: { Authorization: `Bearer ${token}` }})
            .then(response => {
                setEntries([...entries, {...response.data, projectId: response.data.task.section.projectId}]);
            })
            .catch(error => {
                toast.error("Une erreur est survenue", toastOptions);
                console.error("Error saving entry", error);
            });
    }

    const handleEditModel = (model) => {
        setEditedModel({...model, projectId: model.task.section.projectId });
        setModalOpen(true);
    }

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditedModel(null);
    }

    return (
        <>
            <Tooltip placement="bottom-end" leaveDelay={500} id='models-tooltip' title={
                <React.Fragment>
                    <div className='tooltip-models-container'>
                        { models.map((model, index) => (
                            <div className='tooltip-models-line' key={index} onClick={() => handleAddTaskFromModel(model)}>
                                <div className='flex-center gap-1 margin-right'>
                                    <DotsSixVertical size={16} color="#7d7d7d" weight="bold" />
                                    <span>{model.name}</span>
                                </div>
                                <Tooltip placement="left" id='models-tooltip' title={
                                    <React.Fragment>
                                        <div className='tooltip-models-options-container' onClick={(e) => e.stopPropagation()}>
                                            <span className='edit' onClick={() => handleEditModel(model)}>Modifier</span>
                                            <span className='duplicate' onClick={() => handleDuplicateModel(model)}>Dupliquer</span>
                                            <span className='delete' onClick={() => setWarningModal(model)}>Supprimer</span>
                                        </div>
                                    </React.Fragment>
                                }>
                                    <div className='models-options-button' onClick={(e) => e.stopPropagation()}>
                                        <DotsThree size={16} color="#7d7d7d" weight="bold" />
                                    </div>
                                </Tooltip>
                            </div>
                        ))}
                        <span className='new-model' onClick={() => setModalOpen(true)}><Plus size={14} weight="bold"/> Nouveau modèle</span>
                    </div>
                </React.Fragment>
            }>
                <div className='dropdown-right'>
                    <CaretDown size={16} color='white' weight="bold" />
                </div>
            </Tooltip>
            <CustomModal show={modalOpen} setShow={handleCloseModal} title={editedModel?.id ? editedModel.name : 'Nouveau modèle de tâche'}>
                <div className="modal-new-model">
                    <div className='margin-bottom'>
                        <label className='name'>Nom du modèle</label>
                        <input
                            type="text"
                            className="classic-input"
                            value={editedModel?.name ? editedModel.name : ''}
                            onChange={(e) => setEditedModel({ ...editedModel, name: e.target.value })}
                        />
                    </div>
                    <div className='margin-bottom'>
                        <label className='project'>Projet associé</label>
                        <CustomSearchInput
                            className="modal-model-select"
                            isClearable
                            entity={projects}
                            labelType="project"
                            value={editedModel?.projectId ? projects.find(p => p.id === editedModel.projectId) : null}
                            onChange={(value) => setEditedModel({ ...editedModel, projectId: value ? value.id : null, taskId: null })}
                            placeholder="Sélectionnez un projet"
                        />
                    </div>
                    <div className='margin-bottom'>
                        <label className='task'>Tâche associée</label>
                        <CustomSearchInput
                            className="modal-model-select"
                            isClearable
                            entity={editedModel?.projectId ? tasks.filter(t => sections.some(s => s.projectId === editedModel.projectId && s.id === t.sectionId)) : []}
                            labelType="task"
                            value={editedModel?.taskId ? tasks.find(t => t.id === editedModel.taskId) : null}
                            onChange={(value) => setEditedModel({ ...editedModel, taskId: value ? value.id : null })}
                            Option={(props) => <TaskOption {...props} tasks={tasks} sections={sections}/>}
                            placeholder="Sélectionnez une tâche"
                        />
                    </div>
                    <div className='flex-view gap-3 margin-bottom'>
                        <div>
                            <label className='duration'>Durée</label>
                            <input
                                type="number"
                                min={0}
                                step={.25}
                                className="classic-input number-input"
                                value={editedModel?.duration ? editedModel.duration : ''}
                                onChange={(e) => setEditedModel({ ...editedModel, duration: parseFloat(e.target.value) })}
                            />
                        </div>
                        <div className='full-width gap-0'>
                            <label className='comments'>Commentaires</label>
                            <input
                                type="text"
                                className="classic-input"
                                value={editedModel?.comments ? editedModel.comments : ''}
                                onChange={(e) => setEditedModel({ ...editedModel, comments: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='modal-buttons'>
                        <button className='main-button cancel-button' onClick={handleCloseModal}>Annuler</button>
                        <button className="main-button green-button validate" onClick={handleSubmitModel}><Check size={16} color="#f0f0f0" weight="bold" />Enregistrer et créer</button>
                    </div>
                </div>
            </CustomModal>
            <CustomModal show={warningModal !== false} setShow={setWarningModal} title="Attention">
                <div className="modal-new-model">
                    <p>Êtes-vous sûr de vouloir supprimer le modèle : <strong>{warningModal.name}</strong> ?</p>
                    { automations.some(a => a.modelId === warningModal.id) && <p><Lightning size={18} color="#3b61e1" className="margin-right" weight="bold" />Attention, ce modèle est utilisé dans les automatisations, celles-ci seront donc également supprimées.</p>}
                    <p></p>
                    <div className='modal-buttons'>
                        <button className='main-button cancel-button' onClick={() => setWarningModal(false)}>Annuler</button>
                        <button className="main-button red-button validate" onClick={() => handleDeleteModel(warningModal.id)}>Supprimer le modèle</button>
                    </div>
                </div>
            </CustomModal>
        </>
    )
}

export default ModelsButton;