import React, { useState, useContext } from "react";
import { Switch } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import AuthContext from "../../../contexts/AuthContext";

const Notifications = ({ user }) => {
    const { token } = useContext(AuthContext);
    const [emailNotifications, setEmailNotifications] = useState(user.emailNotifications || false);

    const handleEmailNotificationsChange = (event) => {
        const newValue = event.target.checked;
        setEmailNotifications(newValue);

        axios
            .put(
                `${process.env.REACT_APP_UPA_API_HOST}users/${user.id}`,
                {
                    user: {
                        ...user,
                        emailNotifications: newValue,
                    },
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(() => {
                toast.success("Préférences de notification mises à jour", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Erreur lors de la mise à jour", toastOptions);
            });
    };

    return (
        <div className="tab-panel">
            <div className="tab-panel-header">
                <h2>Notifications</h2>
                <span>Vue générale de votre profil, email, rôle...</span>
            </div>
            <div className="input-row-container">
                <span className="input-label">Notifications par email</span>
                <Switch checked={emailNotifications} onChange={handleEmailNotificationsChange} color="primary" />
            </div>
        </div>
    );
};

export default Notifications;
