import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import Login from "./components/login/Login";
import AuthContext from "./contexts/AuthContext";
import AdminPanel from "./components/admin/AdminPanel";
import PasswordForgot from "./components/login/PasswordForgot";
import PrivateRoute from "./components/routes/PrivateRoute";
import Sign from "./components/sign/Sign";
import Tests from "./components/tests/Tests";
import { CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/App.css";

const App = () => {
    const [token, setToken] = useState(localStorage.getItem("session_token"));
    const [user, setUser] = useState(null);
    const [dashboardData, setDashboardData] = useState();
    const [userLoaded, setUserLoaded] = useState(false);

    const contextValue = {
        token: token,
        user: user,
        setToken: setToken,
        setUser: setUser,
    };

    React.useEffect(() => {
        setUserLoaded(false);
        if (localStorage.getItem("session_token")) {
            axios
                .get(process.env.REACT_APP_UPA_API_HOST + "users/me", {
                    headers: { Authorization: "Bearer " + localStorage.getItem("session_token") },
                })
                .then((response) => {
                    setUser(response.data);
                    setUserLoaded(true);

                    axios
                        .get(process.env.REACT_APP_UPA_API_HOST + "dashboard/count-data", {
                            headers: { Authorization: "Bearer " + localStorage.getItem("session_token") },
                        })
                        .then((response) => {
                            // console.log(response.data);
                            setDashboardData(response.data);
                        })
                        .catch((err) => {
                            setDashboardData(-1);
                            console.log(err);
                        });
                })
                .catch((error) => {
                    console.log(error);
                    setUserLoaded(true);
                    setDashboardData(-1);
                });
        } else {
            setUserLoaded(true);
            setDashboardData(-1);
        }
    }, [token]);

    return userLoaded && dashboardData ? (
        <AuthContext.Provider value={contextValue}>
            <Routes>
                <Route
                    path="admin/*"
                    element={
                        <PrivateRoute>
                            <AdminPanel countData={dashboardData} />
                        </PrivateRoute>
                    }
                />
                <Route path="sign/*" element={<Sign />} />
                <Route path="tests/*" element={<Tests />} />
                <Route exact path="login" element={<Login />} />
                <Route exact path="forgot-password" element={<PasswordForgot />} />
                <Route path="*" element={<Navigate to="admin" />} />
            </Routes>
        </AuthContext.Provider>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={30} />
        </div>
    );
};

export default App;
