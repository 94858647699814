import React, { useEffect, useState } from "react";
import CustomTag from "./CustomTag";

const CustomNavbarOption = ({ innerProps, isDisabled, label, subscriptions = [], ...props }) => {
    const customer = props.data;
    const [supportType, setSupportType] = useState("Chargement...");

    useEffect(() => {
        if (subscriptions.length > 0) {
            const subscription = subscriptions.find((sub) => sub.customer.name === customer.data.contact_name);

            if (subscription) {
                const hasPremium = subscription.invoice_template.line_items.some((item) => item.label.includes("Assistance - Premium"));
                const hasEssentiel = subscription.invoice_template.line_items.some((item) => item.label.includes("Assistance - Essentiel"));

                if (hasPremium) {
                    setSupportType("Assistance - Premium");
                } else if (hasEssentiel) {
                    setSupportType("Assistance - Essentiel");
                } else {
                    setSupportType("Pas d'assistance");
                }
            } else {
                setSupportType("Pas d'assistance");
            }
        } else {
            setSupportType("Pas d'assistance");
        }
    }, [subscriptions, customer.data.contact_name]);

    return (
        <>
            <div className="navbar-option-container">
                <div {...innerProps} className="custom-tag-container">
                    <div className="navbar-customer-icon">
                        <img
                            src={customer.logoUrl ? customer.logoUrl : "https://image.store.altelis.com/images/altelis-tools/logo-altelis.png"}
                            alt="navbar-logo"
                        />
                    </div>
                    <div>
                        <span className="navbar-title">{customer.data.contact_name}</span>
                        <div className="navbar-tags">
                            <CustomTag value={customer.data.status} className={" margin-left " + customer.data.status} />
                            <CustomTag
                                value={supportType}
                                className={
                                    supportType === "Assistance - Premium"
                                        ? "premium uppercase margin-left"
                                        : supportType === "Assistance - Essentiel"
                                        ? "accepted uppercase margin-left"
                                        : "essential uppercase margin-left"
                                }
                            />
                            {customer.group ? <CustomTag value={customer.group.name} className=" margin-left" /> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-divider" />
        </>
    );
};

export default CustomNavbarOption;
