import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import TimeEntryList from "./TimeEntryList";

const Tracking = () => {
    return (
        <Routes>
            <Route path="/tracking" element={<TimeEntryList />} />
            <Route path="*" element={<Navigate to="/tracking" />} />
        </Routes>
    );
};

export default Tracking;
