import React from "react";
import Select from "react-select";

const customStyles = {
    control: (provided, { selectProps }) => ({
        ...provided,
        display: "flex",
        height: selectProps.isMulti ? "fit-content" : "2.375rem",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "0.5rem",
        border: "0.0625rem solid var(--black-10)",
        backgroundColor: "var(--white-100)",
        boxShadow: "none",
        width: selectProps.width === 200 ? "200px" : "100%",
        "&:hover": {
            borderColor: "var(--black-10)",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#eee" : "#fff",
        "&:hover": { backgroundColor: "#f2f2f2" },
        color: "#333",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#333",
    }),
};

const CustomSelect = (props) => {
    return <Select {...props} styles={customStyles} className="react-select-container" classNamePrefix="react-select" />;
};

export default CustomSelect;
