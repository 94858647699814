import React, { useContext, useState } from "react";
import AuthContext from "../../../../../contexts/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../../../assets/constants/toast";
import CustomSearchInput from "../../../../custom/CustomSearchInput";
import constants from "../../../../../assets/constants/constants";
import { CircularProgress } from "@mui/material";

const ConfigAdd = ({ setMetasView, zohoId, contact, refresh, setRefresh }) => {
    const [hotel, setHotel] = useState({ zohoId });
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    const handleCreate = () => {
        setButtonLoading(true);
        axios
            .post(process.env.REACT_APP_UPA_API_HOST + "metas/hotels", hotel, { headers: { Authorization: "Bearer " + token } })
            .then(() => {
                setButtonLoading(false);
                toast.success("L'hôtel a bien été ajouté", toastOptions);
                setRefresh(refresh + 1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    };

    return (
        <div>
            <div className="list-header">
                <div className="view-title">
                    <h1>Ajouter une configuration métas</h1>
                </div>
                <div className="view-buttons">
                    <button onClick={() => setMetasView(null)} className="main-button without-icon warning-button">
                        Annuler
                    </button>
                    <button className="main-button blue-button plus margin-left" onClick={handleCreate} disabled={buttonLoading}>
                        {buttonLoading ? <CircularProgress size={20} className="white-loader" /> : "Ajouter"}
                    </button>
                </div>
            </div>

            <div className="input-group without-top">
                <div className="input-row-container">
                    <span className="input-label engine">
                        Type moteur <span className="required">*</span>
                    </span>
                    <div className="input-container">
                        <CustomSearchInput
                            placeholder="Rechercher un moteur"
                            entity={constants.engineTypes.map((engineType) => {
                                return { ...engineType, id: engineType.value };
                            })}
                            labelType="constant"
                            onChange={(newValue) => {
                                setHotel((hotel) => {
                                    return { ...hotel, engineType: newValue.value };
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="input-row-container">
                    <span className="input-label id">
                        Code moteur <span className="required">*</span>
                    </span>
                    <div className="input-container without-icon">
                        <input
                            value={hotel.engineCode ? hotel.engineCode : ""}
                            className="classic-input"
                            placeholder="Entrez un code moteur"
                            onChange={(e) => {
                                setHotel((hotel) => {
                                    return { ...hotel, engineCode: e.target.value };
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="input-row-container">
                    <span className="input-label id">GC Customer</span>
                    <div className="input-container without-icon">
                        <input
                            value={hotel.gocardlessCustomer ? hotel.gocardlessCustomer : ""}
                            className="classic-input"
                            placeholder="Exemple : CU001PJNWG8N1T"
                            onChange={(e) => {
                                setHotel((hotel) => {
                                    return { ...hotel, gocardlessCustomer: e.target.value };
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="input-row-container">
                    <span className="input-label id">GC Mandate</span>
                    <div className="input-container without-icon">
                        <input
                            value={hotel.gocardlessMandate ? hotel.gocardlessMandate : ""}
                            className="classic-input"
                            placeholder="Exemple : MD001Q03TEJ6WM"
                            onChange={(e) => {
                                setHotel((hotel) => {
                                    return { ...hotel, gocardlessMandate: e.target.value };
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="input-row-container">
                    <span className="input-label euro">
                        Taxe appliquée <span className="required">*</span>
                    </span>
                    <div className="input-container">
                        <CustomSearchInput
                            placeholder="Rechercher une taxe"
                            entity={contact.taxes}
                            labelType="tax"
                            onChange={(newValue) => {
                                setHotel((hotel) => {
                                    return { ...hotel, taxId: newValue.tax_id };
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="input-row-container">
                    <span className="input-label user">
                        Contacts <span className="required">*</span>
                    </span>
                    <div className="input-container">
                        <CustomSearchInput
                            placeholder="Rechercher un contact"
                            entity={contact.contactsAvailable}
                            value={
                                hotel.contacts
                                    ? contact.contactsAvailable.filter((contactAvailable) => hotel.contacts.includes(contactAvailable.id))
                                    : null
                            }
                            isMultiSearch
                            labelType="contact_person"
                            onChange={(newValue) => {
                                setHotel((hotel) => {
                                    return { ...hotel, contacts: newValue.map((contact) => contact.contact_person_id) };
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfigAdd;
