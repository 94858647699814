import React, { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import CustomList from "../../custom/CustomList";
import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";

const Souscription = () => {
    const { token } = useContext(AuthContext);

    const capitalize = (value) => {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    };

    const uppercase = (value) => {
        return value.toUpperCase();
    };

    const capitalizeAddress = (address) => {
        return address
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
    };

    const colSouscription = React.useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
                icon: "id",
            },
            {
                Header: "Date",
                accessor: "createdAt",
                icon: "calendar",
                Cell: ({ value }) => dayjs(value).format("DD/MM/YYYY HH:mm"),
            },
            {
                Header: "Nom de l'hôtel",
                accessor: "nom",
                icon: "name",
                Cell: ({ value }) => uppercase(value),
            },
            {
                Header: "Type d'abonnement",
                accessor: "tarifType",
                icon: "abonnement",
            },
            {
                Header: "Prix de l'abonnement",
                accessor: "tarifPrix",
                icon: "euro",
                Cell: ({ value }) =>
                    `${parseFloat(value).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                    })}`,
            },
            {
                Header: "Frais de setup",
                accessor: "fraisSetup",
                icon: "frais-setup",
                Cell: ({ value }) => {
                    const valeurSetup = value
                        ? parseFloat(value).toLocaleString("fr-FR", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                          })
                        : "0";
                    return `${valeurSetup} €`;
                },
            },
            {
                Header: "Adresse de facturation",
                accessor: "adresse",
                icon: "adresse",
                Cell: ({ value }) => capitalizeAddress(value),
            },
            {
                Header: "Ville",
                accessor: "ville",
                icon: "ville",
                Cell: ({ value }) => capitalize(value),
            },
            {
                Header: "Code Postal",
                accessor: "codePostal",
                icon: "id",
            },
            {
                Header: "SIREN",
                accessor: "siren",
                icon: "id",
                Cell: ({ value }) => <CustomTag className="archived" value={value} />,
            },
            {
                Header: "Nom",
                accessor: "nomContact",
                icon: "name",
                Cell: ({ value }) => capitalize(value),
            },
            {
                Header: "Prénom",
                accessor: "prenomContact",
                icon: "name",
                Cell: ({ value }) => capitalize(value),
            },
            {
                Header: "Poste occupé",
                accessor: "posteContact",
                icon: "poste",
                Cell: ({ value }) => capitalize(value),
            },
            {
                Header: "Adresse mail",
                accessor: "emailContact",
                icon: "email",
                Cell: ({ value }) => <CustomTag value={value} />,
            },
            {
                Header: "Téléphone",
                accessor: "telephoneContact",
                icon: "phone",
                Cell: ({ value }) => <CustomTag value={value} />,
            },
            {
                Header: "Adresse mail de facturation",
                accessor: "emailFacturation",
                icon: "email",
                Cell: ({ value }) => <CustomTag value={value} />,
            },
            {
                Header: "ID Stripe",
                accessor: "idStripe",
                icon: "id",
            },
        ],
        []
    );

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title souscription">
                    <h1>Souscriptions Livie</h1>
                </div>
            </div>
            <div className="tabs-container">
                <CustomList
                    entity="souscription/getAll"
                    columns={colSouscription}
                    token={token}
                    isSelectable={false}
                    isClickable={false}
                    isDownloadable={true}
                    isScrollable={true}
                />
            </div>
        </div>
    );
};

export default Souscription;
