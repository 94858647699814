import { Navigate, Routes, Route } from "react-router-dom";
import React from "react";
import FormGenerator from "./FormGenerator";
import Form from "./Form";

const Sign = () => {
    return (
        <Routes>
            <Route exact path="generate" element={<FormGenerator />} />
            <Route exact path=":pageId" element={<Form />} />
            <Route exact path="*" element={<Navigate to="generate" />} />
        </Routes>
    );
};

export default Sign;
