import React from "react";
import { Box, Checkbox, FormGroup, FormControlLabel, Button, Divider, Typography, Modal } from "@mui/material";

const CustomInventaireFilter = ({ ouvert, fermerModal, filtres, changerFiltre, reinitialiserGroupe, reinitialiserTousLesFiltres }) => {
    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: 500,
        width: 400,
        overflowY: "scroll",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 2,
        borderRadius: 2,
    };

    return (
        <Modal open={ouvert} onClose={fermerModal} aria-labelledby="modal-filtres-titre" aria-describedby="modal-filtres-description">
            <Box sx={styleModal}>
                <div className="filter-title-row top">
                    <Typography id="modal-filtres-titre" variant="h6" component="h2">
                        Filtres
                    </Typography>
                    <Button onClick={fermerModal}>Fermer</Button>
                </div>
                <Divider />
                <div className="filter-section">
                    <div className="filter-titre">
                        <Typography variant="subtitle1">CMS</Typography>
                        <Button onClick={() => reinitialiserGroupe("cms")} size="small">
                            Réinitialiser
                        </Button>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("cms", "Webflow")} checked={filtres.cms.includes("Webflow")} />}
                            label="Webflow"
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("cms", "Wordpress")} checked={filtres.cms.includes("Wordpress")} />}
                            label="Wordpress"
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("cms", "Progress9")} checked={filtres.cms.includes("Progress9")} />}
                            label="Progress9"
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("cms", "Autre")} checked={filtres.cms.includes("Autre")} />}
                            label="Autre"
                        />
                    </FormGroup>
                </div>
                <Divider />
                <div className="filter-section">
                    <div className="filter-titre">
                        <Typography variant="subtitle1">Builder utilisé</Typography>
                        <Button onClick={() => reinitialiserGroupe("builder")} size="small">
                            Réinitialiser
                        </Button>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("builder", "Divi")} checked={filtres.builder.includes("Divi")} />}
                            label="Divi"
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("builder", "Dude")} checked={filtres.builder.includes("Dude")} />}
                            label="Dude"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox onChange={() => changerFiltre("builder", "Elementor")} checked={filtres.builder.includes("Elementor")} />
                            }
                            label="Elementor"
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("builder", "Oxygen")} checked={filtres.builder.includes("Oxygen")} />}
                            label="Oxygen"
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("builder", "Autre")} checked={filtres.builder.includes("Autre")} />}
                            label="Autre"
                        />
                    </FormGroup>
                </div>
                <Divider />
                <div className="filter-section">
                    <div className="filter-titre">
                        <Typography variant="subtitle1">Support</Typography>
                        <Button onClick={() => reinitialiserGroupe("support")} size="small">
                            Réinitialiser
                        </Button>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("support", "Assitance Premium")}
                                    checked={filtres.support.includes("Assitance Premium")}
                                />
                            }
                            label="Assitance Premium"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("support", "Assistance Essentiel")}
                                    checked={filtres.support.includes("Assistance Essentiel")}
                                />
                            }
                            label="Assistance Essentiel"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("support", "Pas d'assistance")}
                                    checked={filtres.support.includes("Pas d'assistance")}
                                />
                            }
                            label="Pas d'assistance"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("support", "Nouveau client")}
                                    checked={filtres.support.includes("Nouveau client")}
                                />
                            }
                            label="Nouveau client"
                        />
                    </FormGroup>
                </div>
                <Divider />
                <div className="filter-section">
                    <div className="filter-titre">
                        <Typography variant="subtitle1">Moteur</Typography>
                        <Button onClick={() => reinitialiserGroupe("moteur")} size="small">
                            Réinitialiser
                        </Button>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("moteur", "D-Edge - Smart")}
                                    checked={filtres.moteur.includes("D-Edge - Smart")}
                                />
                            }
                            label="D-Edge - Smart"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("moteur", "D-Edge - Attraction")}
                                    checked={filtres.moteur.includes("D-Edge - Attraction")}
                                />
                            }
                            label="D-Edge - Attraction"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox onChange={() => changerFiltre("moteur", "Reserv'it")} checked={filtres.moteur.includes("Reserv'it")} />
                            }
                            label="Reserv'it"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("moteur", "Pas de moteur")}
                                    checked={filtres.moteur.includes("Pas de moteur")}
                                />
                            }
                            label="Pas de moteur"
                        />
                    </FormGroup>
                </div>
                <Divider />
                <div className="filter-section">
                    <div className="filter-titre">
                        <Typography variant="subtitle1">Web Services</Typography>
                        <Button onClick={() => reinitialiserGroupe("webServices")} size="small">
                            Réinitialiser
                        </Button>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("webServices", "Comparateur de prix")}
                                    checked={filtres.webServices.includes("Comparateur de prix")}
                                />
                            }
                            label="Comparateur de prix"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("webServices", "Google Ads")}
                                    checked={filtres.webServices.includes("Google Ads")}
                                />
                            }
                            label="Google Ads"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("webServices", "Google Analytics")}
                                    checked={filtres.webServices.includes("Google Analytics")}
                                />
                            }
                            label="Google Analytics"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox onChange={() => changerFiltre("webServices", "Métas")} checked={filtres.webServices.includes("Métas")} />
                            }
                            label="Métas"
                        />
                    </FormGroup>
                </div>
                <Divider />
                <div className="filter-section">
                    <div className="filter-titre">
                        <Typography variant="subtitle1">Traduction</Typography>
                        <Button onClick={() => reinitialiserGroupe("traduction")} size="small">
                            Réinitialiser
                        </Button>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={() => changerFiltre("traduction", "WPML")} checked={filtres.traduction.includes("WPML")} />}
                            label="WPML"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox onChange={() => changerFiltre("traduction", "Weglot")} checked={filtres.traduction.includes("Weglot")} />
                            }
                            label="Weglot"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => changerFiltre("traduction", "Google Traduction")}
                                    checked={filtres.traduction.includes("Google Traduction")}
                                />
                            }
                            label="Google Traduction"
                        />
                    </FormGroup>
                </div>
                <div className="filter-title-row bottom">
                    <Button onClick={reinitialiserTousLesFiltres} size="small">
                        Tout réinitialiser
                    </Button>
                    <Button onClick={() => reinitialiserGroupe("traduction")} size="small">
                        Appliquer les filtres
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default CustomInventaireFilter;
