import React, { useEffect, useState, useContext } from "react";
import CustomTag from "../../../custom/CustomTag";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
// import toastOptions from "../../../../assets/constants/toast";
// import { toast } from "react-toastify";
// import Tippy from "@tippyjs/react";
import dayjs from "dayjs";

const ContactInfos = ({ contact, engineConfig }) => {
    const { token } = useContext(AuthContext);
    const [projectsLoaded, setProjectsLoaded] = useState(false);

    const [projects, setProjects] = useState([]);
    const [langues, setLangues] = useState([]);
    const [traduction, setTraduction] = useState([]);
    const [pluginsWordpress, setPluginsWordpress] = useState([]);
    const [gestionMails, setGestionMails] = useState([]);

    const [builder, setBuilder] = useState(null);
    const [cmsVersion, setCmsVersion] = useState(null);
    const [phpVersion, setPhpVersion] = useState(null);
    const [versionWordpress, setversionWordpress] = useState(null);
    const [derniereVersionWordpress, setderniereVersionWordpress] = useState(null);
    const [ndd, setNdd] = useState(null);

    const [dateMEL, setDateMEL] = useState("Non renseigné");
    const [cms, setCms] = useState("Non renseigné");

    const builders = React.useMemo(() => ["Divi", "Elementor", "Oxygen"], []);

    const engineType = engineConfig ? engineConfig.engineType : "Non renseigné";
    const engineUrl = engineConfig ? engineConfig.engineUrl : "Non renseigné";
    const abonnementComparateur = (contact?.recurringLines ?? []).some((line) => line.item_id === "180635000000041836");
    const abonnementMetas = (contact?.recurringLines ?? []).some((line) => line.item_id === "180635000000041422");
    const abonnementWidget = (contact?.recurringLines ?? []).some((line) => line.item_id === "180635000000041782");
    const abonnementAds = (contact?.recurringLines ?? []).some((line) => line.item_id === "180635000000041512");
    const abonnementElfsightInstagram = (contact?.recurringLines ?? []).some((line) =>
        line.description.toLowerCase().includes("instagram".toLowerCase())
    );
    const abonnementElfsightYoutube = (contact?.recurringLines ?? []).some((line) =>
        line.description.toLowerCase().includes("youtube".toLowerCase())
    );
    const abonnementElfsightTiktok = (contact?.recurringLines ?? []).some((line) => line.description.toLowerCase().includes("tiktok".toLowerCase()));
    const abonnementTraduction = (contact?.recurringLines ?? []).some((line) => line.description.toLowerCase().includes("traduction".toLowerCase()));
    const abonnementGoogleMaps = (contact?.recurringLines ?? []).some((line) => line.description.toLowerCase().includes("google maps".toLowerCase()));
    const traductionInfos =
        traduction && traduction.length > 0 ? traduction : cms === "Wordpress" ? "WPML" : abonnementTraduction ? "Weglot" : "Non renseigné";

    const hasAPI = abonnementComparateur || abonnementMetas;
    const apiStatus = hasAPI ? "Oui" : "Non";

    function suppressionEmojisLangues(str) {
        const emojis = /\p{Emoji_Presentation}/gu;
        return str.replace(emojis, "").trim();
    }

    useEffect(() => {
        if (token) {
            axios
                .get(`${process.env.REACT_APP_UPA_API_HOST}projects/`, { headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    setProjects(response.data);
                    setProjectsLoaded(true);
                })
                .catch((error) => {
                    console.error("Impossible de récupérer les projets", error);
                    setProjectsLoaded(true);
                });
        }
    }, [token]);

    useEffect(() => {
        if (projects.length > 0 && contact?.data?.cf_n_du_compte_plan_comptable) {
            const idAltelisBooks = contact.data.cf_n_du_compte_plan_comptable.slice(-3);
            const matchingProject = projects.find((project) => project.data.idProjet?.toString().endsWith(idAltelisBooks));

            console.log("Projet Notion récupéré : ", matchingProject);

            if (matchingProject) {
                setDateMEL(matchingProject.data.dateMEL ? dayjs(matchingProject.data.dateMEL).format("DD/MM/YYYY") : "Non renseigné");
                setCms(matchingProject.data.cms);
                setNdd(matchingProject.data.ndd);

                setCmsVersion(matchingProject.plugins?.versionWordpress);
                setPhpVersion(matchingProject.plugins?.versionPHP);

                const cleanLangues = matchingProject.data.langues
                    ? matchingProject.data.langues.map((langue) => suppressionEmojisLangues(langue))
                    : [];
                setLangues(cleanLangues);
                setTraduction(matchingProject.data.traduction || []);
                setPluginsWordpress(matchingProject.plugins?.plugins || []);

                const foundBuilder = builders.find((builder) =>
                    matchingProject.plugins?.plugins?.some((plugin) => plugin.nomPlugin.includes(builder))
                );
                setBuilder(foundBuilder);
            }
        }
    }, [projects, contact?.data, builders]);

    // useEffect(() => {
    //     if (matchedSiteData) {
    //         const domain = matchedSiteData.site.split('.')[0];
    //         axios.get(process.env.REACT_APP_BACKEND_API_URL + `/emails/${domain}`)
    //             .then(response => {
    //                 setGestionMails(response.data.emails);
    //             })
    //             .catch(error => {
    //                 console.error('Erreur lors de la récupération des adresses e-mail:', error);
    //             });
    //     }
    // }, [matchedSiteData]);

    useEffect(() => {
        const fetchVersionWordpress = async () => {
            const response = await axios.get("https://api.wordpress.org/core/version-check/1.7/");
            const version = response.data.offers[0].version;
            setderniereVersionWordpress(version);
        };
        fetchVersionWordpress();
    }, []);

    useEffect(() => {
        if (cmsVersion && derniereVersionWordpress) {
            setversionWordpress(cmsVersion === derniereVersionWordpress);
        }
    }, [cmsVersion, derniereVersionWordpress]);

    return (
        <div className="infos-container-wrapper">
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper">
                    <p>Site Web</p>
                </div>
                <div className="infos-container mel">
                    <span className="infos-container-title">Date de mise en ligne :</span>
                    <CustomTag className={dateMEL === "Non renseigné" ? "deactivated" : ""} value={dateMEL} />
                </div>
                <div className="infos-container cms">
                    <span className="infos-container-title">CMS :</span>
                    <CustomTag className={cms === "Non renseigné" ? "deactivated" : ""} value={cms} />
                </div>
                {cms === "Wordpress" && (
                    <>
                        <div className="infos-container version">
                            <span className="infos-container-title">Version de PHP :</span>
                            <CustomTag className={phpVersion === "Non renseigné" ? "deactivated" : ""} value={phpVersion} />
                        </div>
                        <div className="infos-container version">
                            <span className="infos-container-title">Version du CMS :</span>
                            <CustomTag
                                className={cmsVersion ? "" : "deactivated"}
                                value={cmsVersion ? `${cmsVersion} ${versionWordpress ? "(à jour)" : "(pas à jour)"}` : "Non renseigné"}
                            />
                        </div>
                        <div className="infos-container builder">
                            <span className="infos-container-title">Builder utilisé :</span>
                            <CustomTag
                                className={builder && builders.includes(builder) ? "fabrics" : "deactivated"}
                                value={builder || "Non renseigné"}
                            />
                        </div>
                    </>
                )}
                <div className="infos-container plugins">
                    <span className="infos-container-title">Plugins / Intégrations :</span>
                    {abonnementElfsightInstagram && <CustomTag className="pink" value="Elfsight Instagram" />}
                    {abonnementElfsightYoutube && <CustomTag className="pink" value="Elfsight YouTube" />}
                    {abonnementElfsightTiktok && <CustomTag className="pink" value="Elfsight TikTok" />}
                    {abonnementGoogleMaps && <CustomTag className="active" value="Google Maps" />}
                    {cms === "Wordpress" &&
                        pluginsWordpress.length > 0 &&
                        pluginsWordpress.map((plugin, index) => <CustomTag key={index} value={`${plugin.nomPlugin} - v${plugin.versionPlugin}`} />)}
                    {!abonnementElfsightInstagram &&
                        !abonnementElfsightYoutube &&
                        !abonnementElfsightTiktok &&
                        !abonnementGoogleMaps &&
                        (!pluginsWordpress || pluginsWordpress.length === 0) && <CustomTag className="deactivated" value="Aucun" />}
                </div>
                <div className="infos-container traduction">
                    <span className="infos-container-title">Traduction :</span>
                    <CustomTag
                        className={traductionInfos === "Aucun" || traductionInfos === "Non renseigné" ? "deactivated" : ""}
                        value={traductionInfos}
                    />
                </div>
                <div className="infos-container languages">
                    <span className="infos-container-title">Langues :</span>
                    {langues.length > 0 ? langues.map((langue, index) => <CustomTag key={index} value={langue} />) : <CustomTag value="Français" />}
                </div>
            </div>
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper">
                    <p>Web Services</p>
                </div>
                <div className="infos-container moteur">
                    <span className="infos-container-title">Moteur de réservation :</span>
                    <CustomTag className={engineType ? "" : "deactivated"} value={engineType} />
                </div>
                <div className="infos-container lien-moteur">
                    <span className="infos-container-title">Lien du moteur :</span>
                    <CustomTag className={engineUrl ? "" : "deactivated"} value={engineUrl} />
                </div>
                <div className="infos-container comparateur">
                    <span className="infos-container-title">Comparateur de prix :</span>
                    <CustomTag className={abonnementComparateur ? "active" : "deactivated"} value={abonnementComparateur ? "Oui" : "Non"} />
                </div>
                <div className="infos-container google-analytics">
                    <span className="infos-container-title">Widget :</span>
                    <CustomTag className={abonnementWidget ? "active" : "deactivated"} value={abonnementWidget ? "Oui" : "Non"} />
                </div>
                <div className="infos-container api">
                    <span className="infos-container-title">API :</span>
                    <CustomTag className={hasAPI ? "active" : "deactivated"} value={apiStatus} />
                </div>
                <div className="infos-container google-ads">
                    <span className="infos-container-title">Google Ads :</span>
                    <CustomTag className={abonnementAds ? "active" : "deactivated"} value={abonnementAds ? "Oui" : "Non"} />
                </div>
                <div className="infos-container google-analytics">
                    <span className="infos-container-title">Google Analytics :</span>
                    <CustomTag className={abonnementAds ? "active" : "deactivated"} value={abonnementAds ? "Oui" : "Non"} />
                </div>
                <div className="infos-container metas">
                    <span className="infos-container-title">Métas :</span>
                    <CustomTag className={abonnementMetas ? "active" : "deactivated"} value={abonnementMetas ? "Oui" : "Non"} />
                </div>
            </div>
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper">
                    <p>Hébergement</p>
                </div>
                <div className="infos-container hebergement">
                    <span className="infos-container-title">Hébergement :</span>
                    <CustomTag className={ndd ? "pink" : "deactivated"} value={ndd ? "OVH" : "Non renseigné"} />
                </div>
                <div className="infos-container gear-six">
                    <span className="infos-container-title">Gestion de l'hébergement :</span>
                    <CustomTag
                        className={ndd === "NDD récupéré" ? "active" : "deactivated"}
                        value={ndd === "NDD récupéré" ? "Chez nous" : ndd === "NDD externe" ? "NDD Externe" : "Non renseigné"}
                    />
                </div>
            </div>
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper">
                    <p>Gestion des mails</p>
                </div>
                <div className="infos-container messagerie">
                    <span className="infos-container-title">Messagerie :</span>
                    <CustomTag value="Non renseigné" />
                </div>
                <div className="infos-container adresse-mail">
                    <span className="infos-container-title">Adresses mails gérées :</span>
                    <CustomTag value="Non renseigné" />
                </div>
            </div>
        </div>
    );
};

export default ContactInfos;
