import React, { useState } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import IconeExtraction from "../../../../assets/icons/extract-blue.svg";
import IconeDownload from "../../../../assets/icons/download--black.svg";
import CustomTag from "../../../custom/CustomTag";

const ContactChatbot = ({ contact, abonnementLivie }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bookingData, setBookingData] = useState(null);
    const [expediaData, setExpediaData] = useState(null);
    const bookingUrl = contact.engineConfigs?.find((config) => config.dedgeConfig?.bookingUrl)?.dedgeConfig.bookingUrl;
    const expediaUrl = contact.engineConfigs?.find((config) => config.dedgeConfig?.expediaUrl)?.dedgeConfig.expediaUrl;
    const livieRecuperation = contact?.recurringLines?.find((line) => line.item_id === "180635000004769204");
    const livieExtraction = livieRecuperation?.description?.match(/Date de démarrage : (.+?)\n/);
    const livieDate = livieExtraction ? livieExtraction[1] : "Non renseigné";

    const extractData = () => {
        if (!bookingUrl && !expediaUrl) return;

        setLoading(true);
        setBookingData(null);
        setExpediaData(null);

        const requests = [];
        if (bookingUrl) {
            const bookingRequest = axios.post("https://sea.altelis.com/chatbot/text-from-booking", { url: `https://${bookingUrl}` });
            requests.push(bookingRequest);
        } else {
            requests.push(Promise.resolve(null));
        }

        // if (expediaUrl) {
        //     const expediaRequest = axios.post('https://sea.altelis.com/chatbot/text-from-expedia', { url: `https://${expediaUrl}` });
        //     requests.push(expediaRequest);
        // } else {
        //     requests.push(Promise.resolve(null));
        // }

        Promise.all(requests)
            .then(([bookingResponse, expediaResponse]) => {
                setBookingData(bookingResponse ? bookingResponse.data : null);
                setExpediaData(expediaResponse ? expediaResponse.data : null);
                setLoading(false);
                toast.success("Les données ont été extraites avec succès", toastOptions);
            })
            .catch(() => {
                toast.error("Une erreur est survenue lors de l'extraction des données", toastOptions);
                setLoading(false);
            });
    };

    const telechargementDonnees = () => {
        if (data) {
            const json = JSON.stringify(data, null, 2);
            const blob = new Blob([json], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "data.json");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div className="tab-container">
            <div className="chatbot-wrapper">
                <div className="chatbot-row">
                    <div className="chatbot-container">
                        <h3>Livie by Altelis</h3>
                        <div className="input-row-container">
                            <span className="input-label large-input statut-blue">Statut :</span>
                            <CustomTag className={abonnementLivie ? "active" : "deactivated"} value={abonnementLivie ? "En ligne" : "Non actif"} />
                        </div>
                        <div className="input-row-container">
                            <span className="input-label large-input calendar-blue">Date de mise en ligne :</span>
                            <CustomTag className="deactivated" value={livieDate} />
                        </div>
                    </div>
                    <div className="chatbot-container">
                        <h3>Extraction de données</h3>
                        <div className="input-row-container">
                            <span className="input-label small-input link-blue">Lien Booking :</span>
                            <CustomTag className={bookingUrl ? "" : "deactivated"} value={bookingUrl || "Non renseigné"} />
                        </div>
                        <div className="input-row-container">
                            <span className="input-label small-input link-blue">Lien Expedia :</span>
                            <CustomTag className={expediaUrl ? "" : "deactivated"} value={expediaUrl || "Non renseigné"} />
                        </div>
                        {abonnementLivie && bookingUrl && !loading && (
                            <button className="chatbot-button extract" onClick={extractData} disabled={loading}>
                                {loading ? <CircularProgress size={20} /> : <img src={IconeExtraction} alt="Icône Extraction" />}
                                <span>Extraire les données</span>
                            </button>
                        )}
                    </div>
                </div>
                {(bookingData || expediaData) && !loading && (
                    <div className="chatbot-data-container">
                        <div className="chatbot-data-header">
                            <h4>Données extraites</h4>
                            <button className="chatbot-button download" onClick={telechargementDonnees}>
                                <img src={IconeDownload} alt="Icône Télécharger" />
                                <span>Télécharger le fichier</span>
                            </button>
                        </div>
                        <div className="chatbot-data-content">
                            {bookingData && (
                                <div>
                                    <pre>{JSON.stringify(bookingData, null, 2)}</pre>
                                </div>
                            )}
                            {bookingData && expediaData && <hr />}
                            {expediaData && (
                                <div>
                                    <pre>{JSON.stringify(expediaData, null, 2)}</pre>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContactChatbot;
