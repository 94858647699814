import React from "react";

const AuthContext = React.createContext({
    token: null,
    user: null,
    setUser: () => {},
    setToken: () => {},
});

export default AuthContext;
