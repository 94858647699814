import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";

import { Database } from "@phosphor-icons/react";

const Scraper = () => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [url, setUrl] = useState("");
    const [data, setData] = useState();

    const handleCreate = () => {
        if (url && url.length > 10) {
            setButtonLoading(true);
            setData(null);
            axios
                .post("https://sea.altelis.com/chatbot/text-from-booking", {
                    url: url,
                })
                .then((response) => {
                    setData(response.data);
                    setButtonLoading(false);
                    toast.success("Les données ont été extraites avec succès", toastOptions);
                })
                .catch(() => {
                    toast.error("Une erreur est survenue", toastOptions);
                    setButtonLoading(false);
                });
        }
    };

    const handleDownload = () => {
        if (data) {
            const json = JSON.stringify(data, null, 2);
            const blob = new Blob([json], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "data.json");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Extraction des données</h1>
                </div>
            </div>
            <div className="view-content">
                <p className="waiting-order-warning">
                    <span>
                        Merci de saisir l'URL de la page <strong>sans paramètre</strong>. Exemple :{" "}
                        <strong>https://www.booking.com/hotel/fr/palym.html</strong>
                    </span>
                </p>
                <div className="d-flex-row">
                    <input
                        type="text"
                        className="classic-input full-width"
                        placeholder="https://www.booking.com/hotel/fr/palym.html"
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <button className="main-button blue-button launch" onClick={handleCreate} disabled={buttonLoading}>
                        <Database size={16} weight="bold" />
                        {buttonLoading ? <CircularProgress size={20} className="white-loader" /> : "Extraire les données"}
                    </button>
                </div>
                {data ? (
                    <div className="chatbot-code-data">
                        <pre>{JSON.stringify(data, null, 4)}</pre>
                        <button className="main-button download-button" onClick={handleDownload} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Scraper;
