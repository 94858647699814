import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import AuthContext from "../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import { ArrowsClockwise, Export } from "@phosphor-icons/react";
import CustomList from "../../../components/custom/CustomList";
import CustomTag from "../../../components/custom/CustomTag";
import dayjs from "dayjs";

const PennylaneSubscription = () => {
    const [isExporting, setIsExporting] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [lastUpdatedAt, setLastUpdatedAt] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const { token } = useContext(AuthContext);

    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_UPA_API_HOST}pennylane/get-subscriptions`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            let subscriptionsData = response.data;

            if (Array.isArray(subscriptionsData)) {
                subscriptionsData = subscriptionsData.sort((a, b) => {
                    const idA = parseInt(a.customer.reference, 10) || 0;
                    const idB = parseInt(b.customer.reference, 10) || 0;
                    return idB - idA;
                });
                setSubscriptions(subscriptionsData);
                setFilteredSubscriptions(subscriptionsData);
                const lastUpdate = response.data.length > 0 ? response.data[0].updatedAt : null;
                setLastUpdatedAt(lastUpdate ? dayjs(lastUpdate).format("DD MMMM YYYY, HH:mm") : null);
                setIsLoaded(true);
            } else {
                toast.error("Les données des abonnements ne sont pas valides.", toastOptions);
                setIsLoaded(true);
            }
        } catch (error) {
            toast.error("Erreur lors de la récupération des abonnements", toastOptions);
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    useEffect(() => {
        const results = subscriptions.filter((subscription) => subscription.customer.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredSubscriptions(results);
    }, [searchTerm, subscriptions]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const syncData = async () => {
        setIsSyncing(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_UPA_API_HOST}pennylane/sync`,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            toast.success("Synchronisation réussie", toastOptions);
            await fetchSubscriptions();
        } catch (error) {
            toast.error("Erreur lors de la synchronisation", toastOptions);
        } finally {
            setIsSyncing(false);
        }
    };

    const downloadCSV = async () => {
        setIsExporting(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_UPA_API_HOST}pennylane/export-subscriptions`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const currentDate = new Date();
            const formattedDate = currentDate
                .toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })
                .replace(/\//g, "_");
            link.setAttribute("download", `abonnements_pennylane_${formattedDate}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            toast.success("Fichier téléchargé avec succès.", toastOptions);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier", toastOptions);
        } finally {
            setIsExporting(false);
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case "finished":
                return "deactivated";
            case "not_started":
                return "not-started";
            case "in_progress":
                return "paid";
            case "stopped":
                return "unpaid";
            default:
                return "";
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "ID Altelis",
                accessor: "customer.reference",
                icon: "id",
                Cell: ({ value }) => <CustomTag value={value || "Non disponible"} />,
            },
            {
                Header: "Nom du client",
                accessor: "customer.name",
                icon: "user",
            },
            {
                Header: "Date de début",
                accessor: "start",
                icon: "calendar",
                Cell: ({ value }) => <CustomTag value={value ? dayjs(value).format("DD MMMM YYYY") : "Non disponible"} />,
            },
            {
                Header: "Statut",
                accessor: "status",
                icon: "role",
                Cell: ({ value }) => <CustomTag value={value || "Non disponible"} className={getStatusClass(value)} />,
            },
        ],
        []
    );

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title pennylane">
                    <h1>Abonnements Pennylane</h1>
                </div>
                <div className="view-buttons">
                    {lastUpdatedAt && (
                        <div className="header-info">
                            <span>Dernière MÀJ : {dayjs(lastUpdatedAt).format("DD/MM à HH:mm")}</span>
                        </div>
                    )}
                    <button className="main-button" onClick={syncData} disabled={isSyncing}>
                        {isSyncing ? (
                            <>
                                <CircularProgress size={16} />
                                <span>Synchronisation en cours...</span>
                            </>
                        ) : (
                            <>
                                <ArrowsClockwise size={16} weight="bold" />
                                Synchroniser
                            </>
                        )}
                    </button>
                    <button className="main-button blue-button" onClick={downloadCSV} disabled={isExporting}>
                        <Export size={16} weight="bold" />
                        {isExporting ? <CircularProgress size={24} /> : "Exporter"}
                    </button>
                </div>
            </div>
            <div className="input-container w-100">
                <div className="single-search-input">
                    <input
                        type="text"
                        placeholder="Rechercher un client..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                </div>
            </div>
            <div className="tabs-container">
                <CustomList entity={filteredSubscriptions} columns={columns} isSelectable={false} isClickable={false} />
            </div>
        </div>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default PennylaneSubscription;
