import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import CustomList from "../../custom/CustomList";
import AuthContext from "../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import CustomTag from "../../custom/CustomTag";

const Facturation = () => {
    const { token } = useContext(AuthContext);
    const [abonnements, setAbonnements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalAbonnements, setTotalAbonnements] = useState(0);
    const [totalMois, setTotalMois] = useState(0);

    const LIVIE_PRODUCT_IDS = [
        "4d067506-d6a0-4d84-9498-8267132caed7",
        "33ac7e7b-1917-4b42-88e9-c5fbeb968a75",
        "6bd11352-6751-463e-b9f8-5a1bdb522f73",
    ];

    const LIVIE_PRODUCT_NAMES = {
        "4d067506-d6a0-4d84-9498-8267132caed7": "Abonnement - Livie (<50)",
        "33ac7e7b-1917-4b42-88e9-c5fbeb968a75": "Abonnement - Livie (<100)",
        "6bd11352-6751-463e-b9f8-5a1bdb522f73": "Abonnement - Livie (<200)",
    };

    useEffect(() => {
        const fetchAbonnements = async () => {
            try {
                const url = `${process.env.REACT_APP_UPA_API_HOST}pennylane/get-subscriptions`;
                console.log("Fetching abonnements from URL:", url);
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                console.log(response.data);

                const filteredAbonnements = response.data
                    .filter((abonnement) => abonnement.invoice_template?.line_items?.some((item) => LIVIE_PRODUCT_IDS.includes(item.product_id)))
                    .sort((a, b) => {
                        const refA = parseInt(a.customer.reference, 10);
                        const refB = parseInt(b.customer.reference, 10);
                        return refB - refA;
                    });

                const totalAbonnementsLivie = filteredAbonnements.length;

                const montantTotalLivie = filteredAbonnements.reduce((total, abonnement) => {
                    const livieItems = abonnement.invoice_template?.line_items?.filter((item) => LIVIE_PRODUCT_IDS.includes(item.product_id));
                    const montantLivie = livieItems.reduce((sum, item) => {
                        return sum + parseFloat(item?.currency_price_before_tax || 0);
                    }, 0);
                    return total + montantLivie;
                }, 0);

                setAbonnements(filteredAbonnements);
                setTotalAbonnements(totalAbonnementsLivie);
                setTotalMois(montantTotalLivie);
                setIsLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des abonnements :", error);
                setError("Une erreur est survenue lors de la récupération des abonnements.");
                setIsLoading(false);
            }
        };

        fetchAbonnements();
    }, [token]);

    const columns = React.useMemo(
        () => [
            {
                Header: "ID Altelis",
                accessor: "customer.reference",
                icon: "id",
                Cell: ({ value }) => <CustomTag value={`${value}`} className="blue" />,
            },
            {
                Header: "Nom du client",
                accessor: "customer.name",
                icon: "user",
                Cell: ({ value }) => value.toUpperCase(),
            },
            {
                Header: "ID Abonnement",
                accessor: "id",
                icon: "id",
                Cell: ({ value }) => <CustomTag value={`${value}`} className="blue" />,
            },
            {
                Header: "Type d'abonnement",
                accessor: "invoice_template.line_items",
                id: "type_abonnement",
                icon: "role",
                Cell: ({ value }) => {
                    const livieItem = value?.find((item) => LIVIE_PRODUCT_IDS.includes(item.product_id));

                    if (livieItem) {
                        let className = "info";

                        switch (livieItem.product_id) {
                            case "4d067506-d6a0-4d84-9498-8267132caed7":
                                className = "pink";
                                break;
                            case "33ac7e7b-1917-4b42-88e9-c5fbeb968a75":
                                className = "production";
                                break;
                            case "6bd11352-6751-463e-b9f8-5a1bdb522f73":
                                className = "premium";
                                break;
                            default:
                                className = "info";
                        }

                        return <CustomTag value={LIVIE_PRODUCT_NAMES[livieItem.product_id]} className={className} />;
                    }
                    return "Non spécifié";
                },
            },
            {
                Header: "Montant Total",
                accessor: "invoice_template.line_items",
                id: "montant_total",
                icon: "euro",
                Cell: ({ value }) => {
                    const livieItems = value?.filter((item) => LIVIE_PRODUCT_IDS.includes(item.product_id));
                    const montantLivie = livieItems.reduce((sum, item) => {
                        return sum + parseFloat(item?.currency_price_before_tax || 0);
                    }, 0);
                    return <CustomTag value={`${montantLivie.toFixed(2)} €`} className="active" />;
                },
            },
        ],
        []
    );

    return (
        <div className="view-container">
            <div className="view-container-header">
                <CustomTag
                    value={
                        <>
                            <span>
                                <strong>Nombre d'abonnements : </strong>
                                {`${totalAbonnements}`}
                            </span>
                        </>
                    }
                    className="blue"
                />
                <CustomTag
                    value={
                        <>
                            <span>
                                <strong>Total mensuel : </strong>
                                {`${totalMois.toFixed(2)} €`}
                            </span>
                        </>
                    }
                    className="blue"
                />
            </div>
            {isLoading ? (
                <div className="loader-container">
                    <CircularProgress size={30} />
                </div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className="tabs-container">
                    <CustomList
                        columns={columns}
                        entity={abonnements}
                        isSelectable={false}
                        isFetchable={false}
                        isClickable={false}
                        isDownloadable={false}
                        isFixed={false}
                    />
                </div>
            )}
        </div>
    );
};

export default Facturation;
