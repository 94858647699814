import React from "react";
import { Route, Routes } from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import RoleList from "../roles/RoleList";
import RoleAdd from "./RoleAdd";
import RoleEdit from "./RoleEdit";

const Roles = () => {
    return (
        <PermissionsGate module="roles">
            <Routes>
                <Route path=":roleId" element={<RoleEdit />} />
                <Route path="new" element={<RoleAdd />} />
                <Route path="*" element={<RoleList />} />
            </Routes>
        </PermissionsGate>
    );
};

export default Roles;
