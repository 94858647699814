import React, { useState } from "react";
import "../../assets/styles/Login.css";
import full_logo from "../../assets/images/main-logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const PasswordForgot = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);

    const handleSubmit = () => {
        setLoader(true);

        axios
            .post(process.env.REACT_APP_UPA_API_HOST + "login/forgot-password", {
                email: email,
            })
            .then((response) => {
                setError("");
                setEmail("");
                setLoader(false);
                setMessage(response.data.message);
            })
            .catch((error) => {
                setLoader(false);
                setError(error.response.data.message);
            });
    };

    return (
        <div className="main-login">
            <div className="login-background" />
            <div className="login-container">
                <div className="login-form">
                    <div className="img-container">
                        <img src={full_logo} alt="logo" width={180} />
                    </div>
                    <div>
                        <h1>Mot de passe oublié</h1>
                        <p className="welcome-message">Renseignez votre adresse email</p>
                        <div className="custom-hr" />
                        {error ? <p className="login-error">{error}</p> : null}
                        {message ? <p className="login-message">{message}</p> : null}
                        <div className="login-input-group">
                            <span>
                                Email <span className="required"> *</span>
                            </span>
                            <input
                                type="email"
                                placeholder="Saisissez votre adresse email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                }}
                            />
                        </div>
                        <Link to="/login" className="forgot-password">
                            Retour à la connexion
                        </Link>
                        <div className="login-button-container">
                            {loader ? (
                                <button className="btn btn-primary login-button-loading" disabled>
                                    <CircularProgress size={20} className="white-loader" />
                                </button>
                            ) : (
                                <button className="btn btn-primary login-button" type="submit" onClick={handleSubmit}>
                                    Envoyer
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordForgot;
