import React, { useState } from "react";
import constants from "../../assets/constants/constants";

const CustomTag = ({ value, className = "", label, isLink = false }) => {
    const [matchedConstant, setMatchedConstant] = useState(null);

    React.useEffect(() => {
        setMatchedConstant(
            constants.all.find((e) => {
                return e.value === value;
            })
        );
    }, [value]);

    const content = matchedConstant ? (
        <span className={"custom-tag " + matchedConstant.value + className}>{label ? label : matchedConstant.label}</span>
    ) : (
        <span className={"custom-tag " + className}>{label ? label : value}</span>
    );

    return isLink && value ? (
        <a href={value} target="_blank" rel="noopener noreferrer">
            {content}
        </a>
    ) : (
        content
    );
};

export default CustomTag;
