import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import Billing from "./metas/Billing";
import PennylaneSubscription from "./PennylaneSubscription";

const Accounting = () => {
    const moduleName = "accounting";

    return (
        <Routes>
            <Route
                path="metas-billing/*"
                element={
                    <PermissionsGate module={moduleName} submodule="metas-billing">
                        <Billing />
                    </PermissionsGate>
                }
            />
            <Route
                path="pennylane-subscription/*"
                element={
                    <PermissionsGate module={moduleName} submodule="pennylane-subscription">
                        <PennylaneSubscription />
                    </PermissionsGate>
                }
            />
            <Route path="*" element={<Navigate to="metas-billing" />} />
        </Routes>
    );
};

export default Accounting;
