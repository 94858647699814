import React from "react";
import { Routes, Route } from "react-router-dom";
import TestSubscribe from "./TestSubscribe";
import TestSubmit from "./TestSubmit";

const Tests = () => {
    return (
        <Routes>
            <Route path=":testId" element={<TestSubscribe />} />
            <Route path=":testId/:userId" element={<TestSubmit />} />
            <Route path="*" element={<TestSubscribe />} />
        </Routes>
    );
};

export default Tests;
