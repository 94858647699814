import React, { useState } from "react";
import { TextField, Button, Stack, CircularProgress } from "@mui/material";
import axios from "axios";

const FormGenerator = () => {
    const [proposalLink, setProposalLink] = useState("");
    const [loading, setLoading] = useState(false);

    const generateForm = () => {
        const pageId = proposalLink.substring(proposalLink.length - 32, proposalLink.length);
        setLoading(true);
        axios
            .post(process.env.REACT_APP_PRODUCTION_URL + "/api/sign/generate-form", {
                pageId: pageId,
            })
            .then((response) => {
                console.log(response);
                setProposalLink("");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="App">
            <div className="form-container">
                <Stack spacing={2}>
                    <TextField
                        id="outlined-basic"
                        label="Lien de la proposition"
                        value={proposalLink}
                        onChange={(e) => setProposalLink(e.target.value)}
                        variant="outlined"
                    />
                    {!loading ? (
                        <Button variant="contained" onClick={generateForm}>
                            Générer le formulaire
                        </Button>
                    ) : (
                        <div>
                            {" "}
                            <CircularProgress />{" "}
                        </div>
                    )}
                </Stack>
            </div>
        </div>
    );
};

export default FormGenerator;
