import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import emptyIcon from "../../assets/images/permission-denied.png";
import { CircularProgress } from "@mui/material";
const PermissionsGate = ({ children, module, submodule }) => {
    const { user } = useContext(AuthContext);
    const userModule = user.role.modules.find((mod) => mod.value === module);

    return user ? (
        userModule && (!submodule || userModule.submodules.find((sub) => sub === submodule)) ? (
            children
        ) : (
            <div className="empty-list-container">
                <img src={emptyIcon} alt="icon" width={110} />
                <span>Vous n'êtes pas autorisé à accéder à ce module.</span>
            </div>
        )
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default PermissionsGate;
