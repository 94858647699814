import React, { useContext, useState } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";

const TestAdd = () => {
    const [test, setTest] = useState({
        hours: 1,
        minutes: 0,
        startDate: dayjs().format("YYYY-MM-DD"),
        stopDate: dayjs().add(1, "month").format("YYYY-MM-DD"),
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleCreate = () => {
        setButtonLoading(true);
        axios
            .post(process.env.REACT_APP_UPA_API_HOST + "tests", test, {
                headers: { Authorization: "Bearer " + token },
            })
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le test a bien été créé", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    };

    React.useEffect(() => {
        console.log(test);
    }, [test]);

    return (
        <div>
            <div className="list-header">
                <div className="header-input">
                    <input
                        type="text"
                        placeholder="Nom du test"
                        value={test.name ? test.name : ""}
                        onChange={(e) => {
                            setTest({ ...test, name: e.target.value });
                        }}
                    />
                </div>
                <div className="view-buttons">
                    <button
                        onClick={() => navigate(-1)}
                        className="main-button without-icon warning-button"
                    >
                        Annuler
                    </button>
                    <button
                        className="main-button blue-button plus margin-left"
                        onClick={handleCreate}
                        disabled={buttonLoading}
                    >
                        {buttonLoading ? (
                            <CircularProgress
                                size={20}
                                className="white-loader"
                            />
                        ) : (
                            "Créer"
                        )}
                    </button>
                </div>
            </div>
            <div className="input-group without-top">
                <div className="input-row-container">
                    <span className="input-label calendar">
                        Dates de validité <span className="required">*</span>
                    </span>
                    <span className="margin-right">Du</span>
                    <input
                        type="date"
                        className="classic-input"
                        value={test.startDate}
                        onChange={(e) =>
                            setTest({ ...test, startDate: e.target.value })
                        }
                    />
                    <span className="margin-right margin-left">au</span>
                    <input
                        type="date"
                        className="classic-input"
                        value={test.stopDate}
                        onChange={(e) =>
                            setTest({ ...test, stopDate: e.target.value })
                        }
                    />
                </div>
                <div className="input-row-container">
                    <span className="input-label calendar">
                        Durée <span className="required">*</span>
                    </span>
                    <input
                        type="number"
                        className="classic-input"
                        min={0}
                        max={10}
                        step={1}
                        value={test.hours}
                        onChange={(e) =>
                            setTest({
                                ...test,
                                hours: parseInt(e.target.value),
                            })
                        }
                        placeholder="Heures"
                    />
                    <span className="margin-left margin-right">
                        heure(s) et
                    </span>
                    <input
                        type="number"
                        className="classic-input margin-right"
                        min={0}
                        max={60}
                        step={15}
                        value={test.minutes}
                        onChange={(e) =>
                            setTest({
                                ...test,
                                minutes: parseInt(e.target.value),
                            })
                        }
                        placeholder="Minutes"
                    />
                    <span>minute(s)</span>
                </div>
                <div className="input-row-container">
                    <span className="input-label role">
                        Lien du sujet <span className="required">*</span>
                    </span>
                    <div className="input-container without-icon">
                        <input
                            type="text"
                            className="classic-input"
                            placeholder="Saisissez un lien Notion"
                            value={test.subjectLink ? test.subjectLink : ""}
                            onChange={(e) => {
                                setTest({
                                    ...test,
                                    subjectLink: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestAdd;
