import { useEffect, useState } from "react";

const CountdownTimer = ({ targetDate, introText, displayDays = true, setRemaining }) => {
    const useCountdown = (targetDate) => {
        const countDownDate = new Date(targetDate).getTime();
        const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

        useEffect(() => {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);
            return () => clearInterval(interval);
        }, [countDownDate]);

        return getReturnValues(countDown);
    };

    const getReturnValues = (countDown) => {
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
        setRemaining(days + hours + minutes + seconds);
        return [days, hours, minutes, seconds];
    };

    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds > 0) {
        return (
            <div className="counter-container">
                <span className="starts-in">{introText}</span>
                <div className="counter-container-block">
                    {displayDays ? (
                        <>
                            <div className="counter-block">
                                <p>{days.toString().padStart(2, "0")}</p>
                                <span>jour(s)</span>
                            </div>
                            <span className="separator">:</span>
                        </>
                    ) : null}
                    <div className="counter-block">
                        <p>{hours.toString().padStart(2, "0")}</p>
                        <span>heure(s)</span>
                    </div>
                    <span className="separator">:</span>
                    <div className="counter-block">
                        <p>{minutes.toString().padStart(2, "0")}</p>
                        <span>minute(s)</span>
                    </div>
                    <span className="separator">:</span>
                    <div className="counter-block">
                        <p>{seconds.toString().padStart(2, "0")}</p>
                        <span>seconde(s)</span>
                    </div>
                </div>
            </div>
        );
    }
};

export default CountdownTimer;
