import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import toastOptions from "../../../assets/constants/toast";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import CustomSearchInput from "../../custom/CustomSearchInput";
import constants from "../../../assets/constants/constants";

const Translator = ({ label = "Déposez votre fichier" }) => {
    const [file, setFile] = useState(null);

    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    const changeHandler = (event) => {
        if (event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target.result;
                const charCount = text.length;
                setFile({
                    source_lang: "EN",
                    content: text,
                    name: event.target.files[0].name,
                    charCount: charCount,
                    estimatedPrice: (charCount * 0.00002).toFixed(2),
                });
            };
            reader.readAsText(event.target.files[0]);
        }
    };

    const handleDownload = (data) => {
        console.log("download", data);
        if (data) {
            const blob = new Blob([data.toString()], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "translation.txt");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleTranslate = () => {
        setButtonLoading(true);
        axios
            .post(
                process.env.REACT_APP_UPA_API_HOST + "deepl/translate",
                {
                    text: file.content,
                    source_lang: file.source_lang,
                    target_lang: file.target_lang,
                },
                { headers: { Authorization: "Bearer " + token } }
            )
            .then((response) => {
                handleDownload(response.data);
                setButtonLoading(false);
                setFile(null);
                toast.success("Le fichier a été traduit avec succès", toastOptions);
            })
            .catch((error) => {
                setButtonLoading(false);
                setFile(null);
                toast.error("Une erreur est survenue", toastOptions);
                console.log(error);
            });
    };

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Traduction Deepl</h1>
                </div>
            </div>
            <p className="waiting-order-warning">
                {file ? (
                    <span>
                        <strong>{file.charCount}</strong> caractères. Estimation du coût de la traduction : <strong>{file.estimatedPrice}€</strong>
                    </span>
                ) : (
                    <span>
                        Déposez votre fichier au format <strong>.txt</strong>
                    </span>
                )}
            </p>
            <div className="translate-container">
                <label htmlFor="file" className={file ? "metas-file-chooser data" : "metas-file-chooser"}>
                    <span>{!file ? label : file.name}</span>
                    <input accept=".txt" type="file" name="file" id="file" onChange={changeHandler} />
                </label>
                {file ? (
                    <>
                        <CustomSearchInput
                            labelType="language"
                            value={file ? constants.deeplLanguages.find((l) => l.code === file.source_lang) : ""}
                            onChange={(newValue) => setFile({ ...file, source_lang: newValue.code })}
                            entity={constants.deeplLanguages}
                            disabled={true}
                        />
                        <CustomSearchInput
                            labelType="language"
                            value={file ? constants.deeplLanguages.find((l) => l.code === file.target_lang) : ""}
                            onChange={(newValue) => setFile({ ...file, target_lang: newValue.code })}
                            entity={constants.deeplLanguages}
                            placeholder="Langue cible"
                            disabled={true}
                        />
                    </>
                ) : null}
                <button className="main-button blue-button launch" disabled={!file || buttonLoading || !file.target_lang} onClick={handleTranslate}>
                    {buttonLoading ? <CircularProgress className="white-loader" size={20} /> : "Traduire le fichier"}
                </button>
            </div>
        </div>
    );
};

export default Translator;
